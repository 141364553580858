import memberAgreementTxtEn from './memberAgreementTxt-en';

// 关于我们
const about = {
  'about.tab.title': 'About us',
  'about.welcome': `Welcome to QuickFox`,
  'about.list.check': 'Check for updates', //'Check for updates',
  'about.list.contact': 'Contact us',
  'about.list.like': 'Rate us 5-star',
  'about.list.terms': 'User Agreement',
  'about.list.policy': 'Privacy Policy',
  'about.list.vip': 'Member Service Agreement',
};

// 联系我们
const contact = {
  'contact.tab.title': 'Contact us',
  'contact.title': 'You can get in touch with us in the following ways',
  'contact.list.offcial': 'Offcial Site',
  'contact.list.wx': 'WeChat Official Account',
  'contact.list.qq': 'Customer Service QQ',
  'contact.list.qqgroup': 'Official QQ Group',
  'contact.list.wxkf': 'Wechat Technical Support',
  'contact.list.email': 'Email',
  'contact.list.op.visit': 'Visit',
  'contact.list.op.copy': 'Copy',
  'contact.list.op.copyAccount': 'Copy', //复制帐号',
  'contact.list.op.copy.mail': 'Copy',
  'contact.copy': 'copy success',
};

// 任务中心
const center = {
  'center.tab.title': 'Task center',
  'center.list.register': 'register account',
  'center.list.bind': 'bind account',
  'center.list.questionnaire': 'Newcomer Questionnaire',
  'center.list.invite': 'invite a friend',
  'center.list.wx': 'Follow QuickFox WeChat',
  'center.list.offcial': 'Join the official QuickFox group',
  'center.list.register.desc':
    'Use your mobile phone number or email to sign up for an account, and you can get a 3-day reward for Silver members',
  'center.list.bind.desc':
    'You can get 2-day reward for Silver members by completing all tasks of binding account',
  'center.list.questionnaire.desc':
    'After completing the questionnaire survey, you can get 2-day reward for Silver members',
  'center.list.invite.desc':
    'After successful invitation, you will be rewarded with silver member for 3 days',
  'center.list.wx.desc':
    'Follow QuickFox WeChat Public to get 1 day Silver Member hour bonus immediately',
  'center.list.offcial.desc':
    'Join the official QuickFox group and get an instant 1 day Silver member hour bonus',
  'center.list.op.register': 'de-register',
  'center.list.op.bind': 'de-bind',
  'center.list.op.questionnaire': 'go to completion',
  'center.list.op.invite': 'go share',
  'center.list.op.wx': 'de-follow',
  'center.list.op.offcial': 'go join',
  'center.list.op.get': 'to get',
  'center.list.op.complete': 'complete',
  'center.register.tips': 'Please register your account first',
  'center.get.success': 'Success!',
  'center.get.fail': 'Failed！',
};

// 问卷调查
const questionnaire = {
  'questionnaire.tab.title': 'Questionnaire',
  'questionnaire.title':
    'In order to provide better service for you, please fill in according to the actual situation. You will be awarded 2 days of Silver Membership for completing this survey',
  'questionnaire.submit': 'submit',
  'questionnaire.success': 'success',
  'questionnaire.fail': 'fail',
  'questionnaire.loading': 'loading...',
  'questionnaire.picker.extra': 'select',
  'questionnaire.picker.title': 'select local',
  'questionnaire.slider.title': 'Please slide the slider',
  'questionnaire.textarea.placeholder':
    'We look forward to your valuable suggestions',
  'questionnaire.submit.tips': 'Please complete all options and submit',
};

// 帮助
const help = {
  'help.tab.title': 'help',
  'help.title': 'Need help? Click online customer service',
  'help.head.speedUp': 'speed up',
  'help.head.recharge': 'recharge',
  'help.head.other': 'other',
  'help.contact': 'Contact', //立即联系',
  'help.pv': '帮助页浏览量', //帮助页浏览量',
  'help.speed': 'Home', //加速',
  'help.other': 'Others', //其他',
  'help.vip': 'Member', //会员',
};

// 帮助详情
const helpDetail = {
  'helpDetail.tab.title': 'Details',
  'helpDetail.about': 'Related questions',
};

const notice = {
  'notice.tab.title': 'Message Center',
  'notice.nodata': 'No data at present~',
  'notice.numberOfMsgJumpClick': '站内信按钮跳转到会员页的点击量', //站内信按钮跳转到会员页的点击量',
  'notice.getItRightnow': 'Click to get', //点击领取',
  'notice.allReadyGetIt': 'Has got', //已领取',
  'notice.getItSuccessfully': 'Successfully received', //领取成功',
  'notice.rechargeNow': 'Recharge now', // '立即充值',
};

// 技术支持
const tsupport = {
  'tsupport.title': 'Technical support',
  'tsupport.wechatDesc': 'QuickFox Technical Support',
  'tsupport.addService': 'Contact Us',
  'tsupport.tip': 'If you experience flashbacks, failures to accelerate, delays, lags, etc. during use, which affect normal use, please contact us.',
  'tsupport.unSupport': 'Not supported yet for',
};

const memberServiceAgreement = {
  'memberServiceAgreement.tab.title': 'Member Service Agreement',
  'memberServiceAgreement.content': memberAgreementTxtEn,
};

const Welfare = {
  'welfare.header.todayincome': 'Today income',
  'welfare.header.totalincome': 'cumulative income',

  'welfare.coinsTasks': 'Gold coin tasks',

  'welfare.tasksCard.chekout': 'View',
  'welfare.tasksCard.coins': 'Gold Coins',

  'welfare.tasks.invite': 'Invite task',
  'welfare.tasks.invite.unit': 'Gold coins/person',
  'welfare.tasks.invite.descript':
    'Invite friends to get gold coins, millions of red envelopes are waiting for you to redeem',
  'welfare.tasks.sign': 'Daily sign in',
  'welfare.tasks.sign.descript': 'You can get up to 50 gold coins per day',
  'welfare.tasks.wx': 'Follow QuickFox WeChat Official Account',
  'welfare.tasks.wx.descript': 'Follow WeChat official account to get 30-coins',
  //Sign in page
  'welfare.sign.dayno': 'Day {day}',
  'welfare.sign.vipTime': '{vipTime}-{vipTimeType} VIP',
  'welfare.sign.day': 'day',
  'welfare.sign.hadSign': 'Checked in consecutively',
  'welfare.sign.sign': 'Sign in',
  'welfare.sign.signed': 'Signed in',
  'welfare.sign.tips': 'Continuous sign-in can get rich rewards~',
  'welfare.sign.successText': 'Sign in successfully',
  'welfare.sign.rule.title': 'rules',
  'welfare.sign.rule.modalTitle': 'Sign in rules',
  'welfare.sign.rule.eventTime': 'Time: 2024-12-24 12:00 to 2025-1-2 18:00',
  'welfare.sign.rule.part1': 'Sign in for {signCount} consecutive {signTimeType} to get {rewardVipTime}-{rewardTimeType} VIP;',
  'welfare.sign.rule.part2': 'Sign in for {signCount} consecutive {signTimeType} to get {rewardVipTime}-{rewardTimeType} VIP;',
  'welfare.sign.rule.part3': 'Sign-in interruption will count days from beginning;',
  'welfare.sign.rule.part4': 'Sign-in rewards will not be recorded if the same device is registered. It is prohibited to obtain sign-in rewards by improper means;',
  'welfare.sign.rule.interpretation': 'QuickFox reserves the right of final interpretation of this activity;',
  'welfare.sign.successTips':
    'Sign-in is successful, {todayReward} gold coins are in hand, remember to come every day~',
  'welfare.sign.signSuccess':
    'Signed in successfully~',
  'welfare.sign.signSuccessVip':
    'Signed in successfully, {vipTime} {timeType} of VIP time received, remember to come every day~',
  'welfare.sign.ruleDetail':
    'Daily check-in to get 2 gold coins, every successful check-in for 7 consecutive days can get extra gold coins rewards, the highest sign-in day can get 50 gold coins, remember to come every day~',
  'welfare.sign.ruleDetail2':
    'Multiple registrations of the same device do not record reward gold coins, and it is forbidden to obtain gold coin rewards through improper means. QuickFox has the right to withdraw gold coin rewards and close the exchange order',
  //Follow the official account page
  'welfare.wx.qrcodeTip.saved': 'The QR code has been saved to the album',
  'welfare.wx.qrcodeTip.failed': 'The QR code save failed',
  'welfare.wx.qrcodeTip.version':
    'You need to download the latest version to save the QR code!',
  'welfare.wx.Intro.title': 'Task Introduction',
  'welfare.wx.Intro.step': 'Step',
  'welfare.wx.Intro.follow': 'Follow',
  'welfare.wx.Intro.reward': 'Reward',
  'welfare.wx.Intro.rewardText1.part1': 'Follow us to get',
  'welfare.wx.Intro.rewardText1.part2': ' {vipCount}-hour VIP time',
  'welfare.wx.Intro.pc.scanit':
    'Use mobile phone WeChat to "scan" the picture below',
  'welfare.wx.Intro.mobile.scanit':
    'Save the QR code below to the album, open WeChat, and select "Scan" the picture',
  'welfare.wx.Intro.follow.descrip':
    'Click the "Follow" button in the upper right corner to complete the task',

  //Invite page
  'welfare.invite.rule.title': 'Rules',
  'welfare.invite.personNum': 'Successfully invited (person)',
  'welfare.invite.viewDetail': 'View Details',
  'welfare.invite.inviteDetail': 'View Details',
  'welfare.invite.coinNum': 'Invitation reward (gold coins)',
  'welfare.invite.dayNum': 'Invitation reward (day)',
  'welfare.invite.duration': 'Received time(day)',
  'welfare.invite.toGoods': 'Gold coin exchange',
  'welfare.invite.rewardDetail': 'Reward Details',
  'welfare.invite.face2face': 'Poster & face-to-face invitation clicks',
  'welfare.invite.copyLink': 'Copy link',
  'welfare.invite.post.saved': 'Poster has been saved to album',
  'welfare.invite.post.failed': 'Poster saving failed',
  'welfare.invite.post.descrip.tips':
    'Save the poster or screenshot and share it with your friends. Invite your friends to scan the QR code. After the successful invitation, you and your friends will each get 100 gold coins.',
  'welfare.invite.post.version':
    'You need to download the latest version to save the poster!',
  'welfare.invite.post.saveBtn': 'Save poster',
  'welfare.invite.post.descrip.regist': `Scan QR code to register <br /> QuickFox account`,
  'welfare.invite.post.descrip.speedOnce': `Download APP <br /> Use once to speed up`,
  'welfare.invite.post.descrip.getCoins': `You and your friends <br /> each get 100 coins`,
  'welfare.invite.process.regist': `Friends click the <br /> invite link to register`,
  'welfare.invite.process.speedOnce': `Download APP <br /> Use once to speed up`,
  'welfare.invite.process.getCoins': `You and your friends <br /> each get 100 coins`,
  'welfare.invite.process.tips':
    '*Friends need to log in to the account registered on the invitation link on a new mobile device (that is, a mobile phone that has not logged in to the QuickFox account before), and use the acceleration once to receive rewards. ',

  'welfare.invite.link.title': 'You have a super VIP to be claimed',
  'welfare.invite.link.descrip':
    'QuickFox accelerator is giving out benefits, you will get a super VIP when you register, and there will be more cash red envelopes waiting for you to grab!',

  'welfare.tasks.invite.desc': 'Invite to earn annual membership card', //邀请赚年卡会员',
  'welfare.tasks.bindEmail': 'Bind to earn', //绑定有礼',
  'welfare.tasks.bindText': 'Go bind', //去绑定',
  'welfare.tasks.bindEmail.descript':
    'Bind email or phone number to get membership time and coins', //绑定邮箱或手机号即可领取会员加速时长及金币',
  'welfare.tasks.bindEmail.desc': '+6-hour VIP time', //+6小时VIP时长',
  'welfare.tasks.bindEmail.bindTimes': '+6-hour VIP', //+6小时VIP',
  'welfare.tasks.share': 'Sharing rewards', //分享有奖',
  'welfare.tasks.share.descript':
    'Share posters on social Apps and upload screenshots getting 1-coin', //在社交平台分享海报，截图上传领取100金币',

  'welfare.wx.follow': 'Follow WeChat official account', //关注公众号',
  'welfare.wx.welfareForInvite': 'Invitation rewards', //邀请有礼',
  'welfare.wx.signIn': 'Sharing rewards', //分享有奖',
  'welfare.wx.toSignIn': 'Check in', //去签到',
  'welfare.wx.toFollow': 'Follow', //去关注',
  'welfare.wx.toInvite': 'Invite', //去邀请',
  'welfare.wx.toGet': 'Can get', //可得',
  'welfare.wx.toGetHighest': 'Get as much as', //最高获得',
  'welfare.invite.post.pcAppTip': 'Please use QuickFox App to save poster!', //请前往QuickFox APP端保存海报!',
  'welfare.invite.historyBtn': 'Invitation Details', // '邀请明细',
};

const PageTitle = {
  'pageTitle.goods': 'Mall',
  'pageTitle.welfare': 'Welfare', //'福利',
  'pageTitle.invite.detail': 'Invitation history',
  'pageTitle.taskDetails': 'Follow us',
  'pageTitle.taskSignin': 'Daily check-in',
  'pageTitle.taskEmail': 'Bind Email',
  'pageTitle.taskShare': 'Share',
  'pageTitle.inviteDetails': 'Invitation rewards', //邀请有礼',
  'pageTitle.taskCenter': 'Task center', //任务中心',
  'pageTitle.appApplyRecord': 'Application history', //申请记录
};

const Common = {
  'common.ok': 'OK',
  'common.confirm': 'Yes', //确定
  'common.cancel': 'Cancel', //取消
  'common.avatar': 'Avatar', //头像',
  'common.iSee': 'I know', //我知道了',
  'common.human': 'People', //人',
  'common.requreUpdate':
    'This version is outdated. Please upgrade to the latest version', //当前版本过旧, 请更新至最新版本',
  'common.currency.dollar': 'dollar', //美元',
  'common.free': 'Free', //免费',
  'common.currency.rmb': 'Yuan', //元',
  'common.init': 'Initializing...',
  'common.payType.offline': 'Offline Payment',
  'common.payType.alipay': 'Alipay',
  'common.payType.wxMiniProg': 'WeChat Mini Program',
  'common.payType.wx': 'Wechat Pay', //微信支付
  'common.payType.bank': 'Debit/Credit Card', //银行卡/信用卡
};

const Tips = {
  'tips.pleaselogin': 'Please log in first',
  'tips.pleaselogin.event':
    'You can participate in the event after logging in~',
  'tips.gologin': 'Go to log in',
  'tips.loadFailed': 'Loading failed',
  'tips.loadFailed.retry': 'Loading failed, please try again later',
  'tips.refreshed': 'Refreshed',
  'tips.refreshFailed': 'Refresh error',
  'tips.saving': 'Saving...',
  'tips.saveQrcode': 'Save QR code',
  'tips.copyed': 'Copied',
  'tips.loadingHard': 'Loading...',
  'tips.loading': 'Loading...', //'加载中...',
  'tips.nodata.forNow': 'No data', //暂无数据',
  'tips.load.success': 'Loading completed',
  'tips.goingToPay': 'Going to pay...', //正在前往支付...',
  'tips.cancelSuccessful': 'Cancel success', //取消成功',
  'tips.failToRefreshOrder': 'Order refresh failed', //订单刷新失败',
  'tips.refreshOrderError': 'Error in refreshing order', //订单刷新出错了',
  'tips.refreshListError': 'Error in refreshing list', //刷新列表出错了',
  'tips.refreshListDone': 'List refreshed', //已刷新列表',
  'tips.pull2Refresh': 'Pull to refresh', // '下拉可以刷新',
  'tips.release2Refresh': 'Release to refresh', // '释放立即刷新',
  'tips.refreshing': 'Refreshing', //'正在刷新',
};

// Http
const http = {
  'http.prompt.networkError': 'Network error', //网络错误',
};

const monthlyVipAgreement = {
  'monthlyVipAgreement.tab.title': 'Automatic Subscription Service Agreement', //连续包月协议',
  // 'monthlyVipAgreement.content': monthlyVipAgreementTxt,
};

// 开通记录
const ActivationRecord = {
  'record.records': 'Activating history', //开通记录',
  'record.options': 'Actions', // '操作',
  'record.memberRefund': 'VIP Refund', //会员退款',
  'record.syncOrder': 'Synchronize', //'同步订单',
  'record.tip': 'Warm remind', //温馨提示',
  'record.tipDesc':
    'Are you sure you want to close the QuickFox VIP continuous monthly subscription service? This will cause your account to stop renewing, and you will not be able to use VIP exclusive lines after expiration.', //您确定要关闭QuickFox超级VIP连续包月服务吗？这将导致您的帐号停止续期，到期后将无法使用VIP专属线路。',
  'record.memberPage.pageViewsOfActivationRecord': '会员页-开通记录页浏览量', //会员页-开通记录页浏览量',
  'record.refundFail.tryAgain': 'Refund request failed, please try again later', //退款申请失败, 请稍候再试',
  'record.cancel': 'Cancel', //取消',
  'record.noRecordYet.goAndActive':
    'No record yet. Hurry up and become a member~', //还没有记录哦, 快去开通会员吧~',
  'record.chooseRefundOrder': 'Select an order to refund', //选择退款订单',
  'record.weSupportRefund.oneUserHasOneService':
    'We offer 7-day no reason to refund service. Please select an order to be refunded. After the refund, the funds will be returned to the original way. A user can only enjoy one refund service.', //我们支持您在购买超级VIP会员后7天内申请退款, 请选择要退款订单, 退款后资金将原路返回。一个用户只可享受一次退款服务。',
  'record.failToGetList': 'Getting list failed', //获取列表失败',
  'record.failToGetActivationList': 'Getting activating history failed', //获取开通记录列表失败',
  'record.failToGetRefund': 'Getting refund history failed', //获取退款情况失败',
  'record.getListFail': 'Getting list failed', //获取列表失败',
  'record.recordItem.numberOfRefund':
    '会员页-开通记录页, 订单卡片上的退款按钮点击量', //会员页-开通记录页, 订单卡片上的退款按钮点击量',
  'record.recordItem.getTime': 'Get VIP time', //获得时间',
  'record.recordItem.refundTime': 'Refund Time', //退款时间',
  'record.recordItem.refuseRefund': 'None-refundable', //不可退款',
  'record.recordItem.refundComplete': 'Refund completed', //退款已完成',
  'record.recordItem.addDay': 'Benefit time', //权益时长',
  'record.recordItem.handsel': 'Give', //赠送',
  'record.recordItem.orderNumber': 'Order number', //订单编号',
  'record.recordItem.orderType': 'Payment methods', //支付方式',
  'record.recordItem.orderNumberHasBeenCopied': 'Order number copied', //订单编号已复制',
  'record.recordItem.refund': 'Refund', //退款',
  'record.recordItem.outActivity': '外部活动',
  'record.resaonModal.refundReason': 'Refund reason', //退款原因',
  'record.resaonModal.supplement': 'Additional Notes', //补充说明',
  'record.resaonModal.refundReasonPlaceholder':
    'Optional. Please tell us the reason to refund, so that we can optimize ASAP. Enter less than 1 characters.', //选填。告诉我们为什么您需要退款, 以便我们尽快进行优化, 最多可输入50个字。',
  'record.resaonModal.reasonLimitedText':
    'Only 50 characters can be entered at most', //最多只能输入50个字哦',
  'record.resaonModal.failToSubmitRefundRequset':
    'Failed to submit refund request', //退款申请提交失败了',
  'record.resaonModal.refundApplication': 'Refund request', //退款申请',
  'record.reasonModal.failToRefund': 'Operation failed', //操作失败',
  'record.reasonModal.successfulRefund': 'Operation successful', //操作成功',
  'record.reasonModal.weSupportRefund.reviewDetailonITunes':
    'We support you to apply for a refund within 7 days after purchasing a VIP membership, please check the order on iTunes and apply for a refund', //我们支持您在购买超级VIP会员后7天内申请退款, 请在iTunes上查看该订单并进行退款申请。',
  'record.reasonModal.numberOfConfirmRefund':
    '会员页-开通记录页, 确定退款按钮点击量', //会员页-开通记录页, 确定退款按钮点击量',
  'record.reasonModal.ok': 'OK', //好的',
};

// 时间类型(通用)
const TimeType = {
  'timeType.day': 'day', //天',
  'timeType.days': 'days', //天',
  'timeType.week': 'week', //周',
  'timeType.month': 'month', //月',
  'timeType.season': 'quarter', //季',
  'timeType.year': 'year', //年',
  'timeType.minute': 'minute', //分钟',
  'timeType.hour': 'hour', //小时',
  'timeType.sentence': '{day} day {hour} hour {minute} minute {second} second', // {day} 天 {hour} 时 {minute} 分 {second} 秒',
};

// 防锁定教程
const tutorial = {
  'tutorial.mi': 'Xiaomi tutorial', //小米手机教程',
  'tutorial.vivo': 'Vivo tutorial', //vivo手机教程',
  'tutorial.oppo': 'OPPO tutorial', //OPPO手机教程',
  'tutorial.huawei': 'Huawei tutorial', //华为手机教程',
  'tutorial.honor': 'Honor tutorial', //荣耀手机教程',
  'tutorial.oneplus': 'One plus tutorial', //一加手机教程',
  'tutorial.samsung': 'Samsung tutorial', //三星手机教程',
  'tutorial.general': 'Other tutorial', //其他手机教程',
  'tutorial.tips':
    'Please select your mobile phone brand to view the corresponding tutorial', //请选择您的手机品牌, 查看相对应教程',
  'tutorial.title': 'Tutorials', //加速防掉线教程',
  'tutorial.purchase.title': 'Purchase VIP', //购买超级VIP',
  'tutorial.purchase.content0':
    'If you cannot complete the payment when you use GooglePlay to pay for the membership, you can try  following options: ', //如您在使用Google Play支付购买会员时无法完成支付，可尝试使用以下途径购买：',
  'tutorial.purchase.title1':
    '1. Go to our official website to purchase membership', //1、前往官网购买会员',
  'tutorial.purchase.content1':
    'You can click the button below to go to our official website to purchase membership. We support WeChat,Alipay,Paypal and other payment methods, the price is more favorable;', //您可点击下方按钮前往官网购买会员，支持微信/支付宝/PayPal等支付方式，价格更优惠；',
  'tutorial.purchase.title2': '2. Install the latest version', //2、安装QuickFox最新版本',
  'tutorial.purchase.content2':
    'Go to our official website to download the latest version. We support WeChat,Alipay,Paypal,etc.', //可前往QuickFox官网下载最新版本，支持微信/支付宝/PayPal等支付方式；',
  'tutorial.purchase.title3': '3. Contact customer service to purchase', //3、联系客服购买',
  'tutorial.purchase.content3':
    'QuickFox supports the purchase of VIP through WeChat, Alipay, and banks by scanning codes or transferring money. You can click on the upper left corner of the app to contact customer service or add the official WeChat account quickfox2020 for consultation;', //QuickFox支持通过微信/支付宝/银行以扫码或转账的形式购买超级VIP，您可以点击APP左上角咨询APP客服或添加官方微信号QuickFox2020进行咨询；',
  'tutorial.purchase.button': 'Purchase on official website', //去官网页面购买',
  'tutorial.purchase.button1': 'Download the latest version', //下载最新版本',
};
// 邀请裂变1, 2期活动结束
const pointEventEnd = {
  'pointEventEnd.tooltipText': 'Sorry the event has ended', //很遗憾, 活动已结束',
  'pointEventEnd.invitationWithPrize': 'Share for rewards', //邀请有奖',
  'pointEventEnd.scanQRToFollow':
    'Scan the QR code to follow us and make an appointment next time~', //扫码关注公众号 下次再约哦~',
  'pointEventEnd.scanQRToFollowForMoreInfo':
    'Grasp more exciting event information at your fingertips', //扫码关注QuickFox公众号, 更多精彩活动资讯一手掌握',
  'pointEventEnd.copyOfficialAccount': 'Copy', //复制公众号',
};
// 邀请裂变第3期
const taskCenterV3 = {
  'task.mission.pv': '任务中心页浏览量', //任务中心页浏览量',
  'task.lookforwardForMoreTasks': 'Stay tuned for more tasks', //更多任务敬请期待',
  'task.taskCard.isPcAppNotice':
    'The invitation task is only supported on mobile App now, please scan the QR code to download QuickFox for the task', //邀请任务暂时仅支持在移动端完成, 请扫码下载QuickFox APP进行任务。',
  'task.taskCard.numberOfInvitationClick': '邀请任务点击量', //邀请任务点击量',
  'task.taskCard.numberOfFollowTaskClick': '关注公众号任务点击量', //关注公众号任务点击量',
  'task.taskCard.numberOfCheckInClick': '福利页, 签到任务点击数', //福利页, 签到任务点击数',
  'task.detail.follow.numberOfFollowPage': '关注公众号任务页浏览量', //关注公众号任务页浏览量',
  'task.invite.invitePagePv': '邀请任务页浏览量', //邀请任务页浏览量',
  'task.invite.cpoyPv': '复制邀请链接点击量', //复制邀请链接点击量',
  'task.invite.loadingText': 'No record yet, go invite friends now~', //还没有记录哦,快去邀请朋友吧~',
  'task.invite.nodataText': 'No record yet, finish task to get more coins~', //还没有记录哦, 快去做任务获得金币吧~',
  'task.invite.noContentText': 'No records', //暂无记录',
  'task.invite.noContentTextPart1': 'go invite friends now', //快去邀请朋友吧',

  'task.invite.coinDetail': 'Coin history', //金币明细',
  'task.invite.coinDetailPv': '金币明细浏览量', //金币明细浏览量',

  'task.invite.numberOfClickInviteBtn': '邀请按钮点击量', //邀请按钮点击量',
  'task.invite.posterAndFace2Face': 'Poster & F2F invite', //海报&面对面邀请',
  'task.invite.inviteForNow': 'Invite now', //立即邀请',
  'task.invite.linkCopied': 'Link copied', //链接已复制'
  'task.invite.posterLoading': 'Loading poster', //海报正在加载...',
  'task.invite.descript.title.part1': 'Each invited friend can get up to', //每邀请一位好友, 最多可以获得',
  'task.invite.descript.title.part2': 'Go invite now', //快去邀请吧',

  'task.invite.reward.descrip': 'Reward description', //奖励说明',
  'task.invite.reward.descrip.part1':
    '1. You and your friend will each receive a 1-day VIP once the invited friend accelerates on mobile device.',

  'task.invite.reward.descrip.part2':
    '2. After the invited friend finish purchase, you can get 10% of the friend\'s purchase time as an invitation reward.',
  'task.invite.reward.descrip.part3':
    '3. Batch registration using improper means will be deemed as illegal registration and you will not be able to receive invitation rewards.',
  'task.invite.reward.descrip.part4':
    '4. If a friend refunds, the corresponding invitation reward you received will be cancelled simultaneously.',
  'task.invite.reward.descrip.part5':
    '2. After the invited friend finish purchase, you can get 10% + 5% of the friend\'s purchase time as an invitation reward.',
    

  'task.invite.details.tab.waitingData': 'To be accelerate', //待加速',
  'task.invite.details.tab.waitingToGiveData': 'To be released', //待发放',
  'task.invite.details.tab.completedData': 'Completed', //已完成',
  'task.invite.details.tab.inValidData': 'Has expired', //已失效',
  'task.invite.details.tab.closed': 'Closed', //已关闭',
  'task.invite.details.tab.toAppeal': 'Pending appeal', //待申诉',
  'task.invite.details.tab.toPaid': 'To be paid', //待支付',
  'task.invite.details.tab.violationData': 'Offending user', //违规用户',
  'task.invite.details.content.type1':
    'Your friend has completed registration. Let your friend download QuickFox on a new device and speed up once. The reward will be credited within 1 hour.', //好友已注册, 让好友在新设备上下载APP并使用该注册手机号登录进行一次加速, 奖励立刻到账！',
  'task.invite.details.content.type2':
    'You have earned 1-day VIP by successfully invited a friend', //已成功邀请好友, 1天VIP时长奖励到帐咯',
  'task.invite.details.content.type3':
    'Oops, this friend did not perform the first acceleration on a new device, so this invitation failed~', //唉呀, 好友没有在新设备进行首次加速, 本次邀请失效了~',
  'task.invite.details.content.type4':
    'You have successfully invited a friend and is under system risk review. The 1-day VIP for you and your friend will be credited within 1 hour', //已成功邀请好友, 系统风险审核中，您和好友的1天VIP时长奖励将在1小时内到帐',
  'task.invite.details.content.type5':
    'The friend has violated the rules and cannot be issued rewards', //该好友存在违规操作, 无法发放奖励',
  'task.invite.details.loading': 'Loading invitation history...', //邀请明细正在加载...',
  'task.invite.details.load.faild': 'Failed to load invitation history', //邀请明细加载失败',

  'task.signin.viewPv': '福利-签到页, 页面浏览量', //福利-签到页, 页面浏览量',
  'task.signin.walfare': 'Sign-in benefits for new users', //新用户签到福利',
  'task.signin.walfare.detail':
    'If you sign in for 3 consecutive days in the first 7 days after accepting the friend invitation to register, you can get extra 100 coin. You can also participate in the invitation event and invite friends to sign in together to get the same sign-in coin reward.', //接受好友邀请注册后前7天, 连续签到3天, 将可以获得额外100金币奖励。您也可以参与邀请活动, 邀请朋友一起签到, 可获得同等签到金币奖励哟。',
  'task.signin.walfare.downloadForSignin':
    'Scan the code to download QuickFox and invite friends to sign in', //扫码下载QuickFox APP邀请好友签到',
  'task.signin.walfare.goToInviteWithPhone': 'Go invite in mobile App', //去手机端邀请',
  'task.signin.walfare.goToInvite': 'Invite>', //去邀请>',
  'task.signin.walfare.numberOfCheckInClick': '福利-签到任务页, 签到点击数', //福利-签到任务页, 签到点击数',
  'task.signin.walfare.signing': 'Loading check-in data please wait...', //签到数据加载中, 请稍后...',
  'task.signin.walfare.signSuccessTipsPart1':
    'Sign in successfully and {todayReward} coin in hand', //签到成功, {todayReward}金币到手, ',
  'task.signin.walfare.signSuccessTipsPart2':
    'Because you get continuous sign-in benefits as a new user, we will offer you another 100 coin~', //因您获得新用户连续签到福利, 再送您100金币~',
  'task.signin.walfare.signSuccessTipsPart3': 'Remember to come every day~', //记得每天都来哦~',

  'task.goods.detail.title': 'Goods detail', //商品详情',
  'task.goods.detail.lessCoin': 'Insufficient coins', //金币不足',
  'task.goods.detail.lessCoinTips':
    "You don't have enough coins, hurry up and do tasks to earn more coin~", //你的金币不足哦, 快去做任务赚取金币吧~',
  'task.goods.detail.lessCoinTips.goForMoreCoin':
    "You don't have enough coins, hurry up and do tasks to earn more coin~<br>iOS devices do not currently support the exchange of the price difference. Please perform the exchange operation on other devices", //你的金币不足哦, 快去做任务赚取金币吧~<br>ios设备暂不支持补差价兑换, 请在其他系统设备上进行兑换操作

  'task.goods.detail.exchangeRes.goCheck':
    'Successfully redeemed, you can go to "Exchange History" page to view the received items', //已兑换成功，可前往“兑换记录”页面查看领取商品',

  'task.goods.detail.exchange': 'Exchange for goods', //兑换商品',
  'task.goods.detail.exchangeSuccessful': 'Redeem succeeded', //兑换成功',
  'task.goods.detail.exchangeSuccessful.congratulate':
    'Congratulations, you have successfully exchanged. You can receive the item through the following ways~', //恭喜, 你已兑换成功, 可通过下列途径领取商品~',
  'task.goods.detail.exchangeSuccessful.tips':
    'Congratulations, you have successfully applied for exchange. We will complete the review within 2 working days. You can check the progress on the "Exchange History" page~', //恭喜, 你已申请兑换成功, 我们将在2个工作日内完成审核, 可在“兑换记录”页面查看进度哦~',
  'task.goods.detail.exchangeSuccessful.getVip':
    'Successfully exchanged. VIP privileges have been recharged to your account', //兑换成功, 超级VIP特权已充值到您账户',
  'task.goods.detail.exchange.fail.retry':
    'Exchange failed, please try again later', //兑换失败, 请稍后再试',
  'task.goods.detail.exchangeConfirm': 'Are you sure to exchange this?', //确定兑换该商品吗？',
  'task.goods.detail.stock.less': 'Out of stock', //库存不足',
  'task.goods.detail.stock.less.tips':
    'This item is out of stock and cannot be exchanged temporarily~', //该商品库存不足, 暂时无法兑换哦~',
  'task.goods.detail.signinDay.less': 'Insufficient check-in days', //签到天数不足',
  'task.goods.detail.signinDay.less.tips':
    'This item needs to be signed in {day} day and above before it can be redeemed. Hurry up and sign in.', //该商品需要签到{day}次及以上才可以兑换哦, 快去签到吧~',
  'task.goods.detail.remain': 'left', //剩余',
  'task.goods.detail.tillNext': 'The next rush is coming in', //距下次抢购还有',
  'task.goods.blockTitle': 'Goods desciption', //商品介绍',
  'task.goods.noStock': 'Out of stock', //暂无库存',
  'task.goods.noConvertible': 'Exchanging unavailable', //不可兑换',
  'task.goods.overConvertibleLimit': 'Reached the exchange limit', //已达兑换上限',
  'task.goods.exchange.now': 'Exchange now', //立即兑换',
  'task.goods.order.total': 'Order total', //订单总额',
  'task.goods.coinForPay': 'Pay coins', //支付金币',
  'task.goods.require.diff': 'need to make up the difference', //需补差价',
  'task.goods.pay.diff': 'Make up the difference', //补差价',
  'task.goods.pay.diff.exchange': 'Pay the difference to exchange', //支付差价兑换',
  'task.goods.pay.way': 'Payment methods', //选择支付方式',
  'task.goods.contact.offcialAccount':
    'Contact QuickFox WeChat customer service {number}', //联系QuickFox微信客服{number}',
  'task.goods.offcialAccount.copy': '(Copy WeChat account)', //(点击复制微信号)',
  'task.goods.contact.customerService':
    'Contact QuickFox WeChat customer service', //联系QuickFox微信客服',
  'task.goods.go': 'Go', //'立即前往',

  'task.point.detail.coinType0': 'Coin lottery', //金币抽奖',
  'task.point.detail.coinType1': 'Coin exchange', //金币兑换',
  'task.point.detail.coinType2': 'Invite your friend {account} to register', //邀请好友 {account} 注册',
  'task.point.detail.coinType3': 'Sign up by friend invitation', //通过好友邀请注册',
  'task.point.detail.coinType4': 'User ranking top platform rewards', //用户排名靠前平台奖励',
  'task.point.detail.coinType5': 'Daily check-in', //每日签到',
  'task.point.detail.coinType6': 'Follow WeChat official account', //关注微信公众号',
  'task.point.detail.coinType7': 'Mall exchange order invalid', //商城兑换订单失效',
  'task.point.detail.coinType8': 'Coin lottery proceeds', //金币抽奖收益',
  'task.point.detail.coinType9':
    'Additional rewards for signing in for 3 consecutive days', //连续签到3天额外奖励',
  'task.point.detail.coinType10':
    'Additional rewards for friends signing in for 3 consecutive days', //好友连续签到3天额外奖励',
  'task.point.detail.bindReward': 'Binding reward', //'绑定赠送',
  'task.point.detail.shareReward': 'Share task reward', //'分享任务奖励',
  'task.point.detail.coinType11': 'Mid-Autumn Festival event coin gift', //中秋活动金币赠送',
  'task.point.detail.coinType13': 'Expired coins', //金币到期',
  'task.point.detail.coinType16': 'World Cup Quiz', //世界杯竞猜活动',

  'task.redeem.modal.title': 'Not supported yet for', //暂不支持',
  'task.redeem.modal.content':
    ' iOS devices do not currently support the exchange of the price difference. Please perform the exchange operation on other devices', //ios设备暂不支持补差价兑换, 请在其他系统设备上进行兑换操作',
  'task.redeem.exchangeRecordPv': '兑换记录浏览量', //兑换记录浏览量',
  'task.redeem.exchangeRecord': 'Exchange record', //兑换记录',
  'task.redeem.exchangeCode': 'Redemption code: ', //兑换码：',
  'task.redeem.draw.link': 'Receive', //领取链接：',
  'task.redeem.draw.code': 'Get product code:', //领取商品码：,
  'task.redeem.exchangeCode.use': 'Exchange method', //兑换方式',
  'task.redeem.norecord.goForIt': 'No records, go exchange items now~', //还没有记录哦, 快去兑换商品吧~',
  'task.redeem.record.tips':
    'QuickFox will complete the distribution within 2 working days (Monday to Friday), please check and use it ASAP after the distribution', //QuickFox将在2个工作日（周一至周五为工作日）内完成发放, 发放后请尽快查看使用, ',
  'task.redeem.clickForIt': 'View', //点此查看',
  'task.redeem.copy': 'Copy', //复制',
  'task.redeem.copied': 'Copied', //已复制',
  'task.redeem.payForNow': 'Pay now', //立即支付',
  'task.redeem.orderWill': 'Order expires in {time}, please pay in time', //订单将于 {time} 失效, 请及时支付',
  'task.redeem.dataReady': 'Preparing data...', //数据准备中, 请稍候...',
  'task.redeem.drawed': 'Draw', //抽中',
  'task.redeem.receive': 'Get now', //立即领取',
  'task.redeem.drawed.limitDate': 'Prizes are valid until', //奖品有效期至',
  'task.redeem.drawed.waitForit':
    'You have won {lotteryName} and to be distributed (distribution will be completed within 2 working days)', //抽中 {lotteryName} , 等待发放（2个工作日内完成发放）',
  'task.redeem.drawed.waitForit.physical':
    'You have won {lotteryName}, please contact WeChat customer service chenhan_xy to receive', //抽中 {lotteryName} , 请联系微信客服chenhan_xy领取',
  'task.redeem.drawed.violation':
    'You have won {lotteryName}. However, the order has been closed due to illegal acquisition of coins', //抽中 {lotteryName} , 因违规获得金币, 该订单已被关闭',
  'task.redeem.drawed.success':
    'You have won {lotteryName} and membership has been recharged to your account, please pay attention to check', //抽中 {lotteryName} , 会员已充值到您账户, 请注意查收',
  'task.redeem.drawed.goods.success':
    'You have won {lotteryName} and it has been recharged to your account, please pay attention to check', //抽中 {lotteryName} , 已充值到您账户, 请注意查收',
  'task.redeem.drawed.goods.success.physical':
    'You have won {lotteryName} and distribution has completed', //抽中 {lotteryName} , 奖品已完成发放。',
  'task.redeem.drawed.svip.success':
    'VIP membership have been recharged to your account, please pay attention to check', //超级VIP会员已充值到您账户, 请注意查收',
    'task.redeem.drawed.vip.success':
    'VIP membership have been recharged to your account, please pay attention to check', //VIP会员已充值到您账户, 请注意查收',
  'task.redeem.drawed.violation.closed':
    'The order has been closed due to illegal acquisition of coins', //因违规获得金币, 该订单已被关闭',
  'task.redeem.violation.contact':
    'Coins obtained due to violations will not be awarded as prizes for the lottery. If you have any questions, please send appeal materials by email to: info@quickfox.com.cn', //因违规获得金币, 不予发送抽奖奖励。如有疑问请发申诉材料到邮箱：info@quickfox.com.cn。',
  'task.redeem.limit.contact':
    'Due to the abnormal operation of the invited account, the redemption is temporarily restricted. Please send appeal materials by email to: info@quickfox.com.cn, including the chat records of all friends recently invited by your QuickFox account', //因被邀请账号存在异常操作, 暂且限制兑换。请发申诉材料到邮箱：info@quickfox.com.cn。申诉材料包括QuickFox账号, 近期邀请所有好友的聊天记录进行申诉。',
  'task.redeem.order.fail':
    'The order has been invalid and coins consumed are returned', //该笔订单已失效, 所消耗金币已退回账户',

  'task.redeem.draw.customer.service':
    'Please check the detailed description of the redeemed product in time, and add customer service WeChat to complete the redemption.', //请及时查看兑换商品的详情描述，添加客服微信完成兑换。',
  'task.redeem.drawLink.tips':
    'Claim：to be distributed (distribution will be completed within 2 working days)', //领取链接：等待发放（2个工作日内完成发放）',
  'task.redeem.drawLink.limit.tips':
    'Product code will expire on {expireTime}, please redeem it ASAP', //商品码将在{expireTime}过期, 请尽快兑换',
  'task.redeem.noname': 'No name yet', //暂无名称',
  'task.redeem.checkMethod': 'View redemption method', //'查看兑换方式',

  'task.event.rule': 'Rules', //活动规则',
  'task.event.question': 'Common questions', //常见问题',
  'task.event.needToKnow': 'Activity Notice', //活动须知',

  'task.goods.loadGoods.fail': 'Loading item list failed', //商品列表加载失败',
  'task.goods.coin.loadFailed': 'Loading coin number failed', //金币数加载失败',
  'task.goods.shop.requreUpdate':
    'The access to the coin mall is abnormal. To ensure a better user experience, please upgrade to the latest version', //金币商城访问异常, 为保证更好的用户体验, 请您升级到最新客户端',
  'task.goods.coin.less.loadFailed': 'Failed to load coin balance', //剩余金币数加载出错了',
  'task.goods.coin.balance': 'Coin balance', //金币余额',
  'task.goods.coin.detail.view': 'Coin outcome/income history', //查看金币收支记录',
  'task.goods.coin.exchange.record': 'View exchange orders', //查看商城兑换订单',

  'task.lottery.detailPv': '抽奖商品详情页浏览量', //抽奖商品详情页浏览量',
  'task.lottery.waitMsg': 'Wait for the lottery draw, it will be drawn soon~', //正在抽奖, 再等等, 马上开奖了~',
  'task.lottery.drawOut':
    'The prizes have been exhausted. We are replenishing the prizes. Come back later.', //奖品被抽光啦, 正在补充奖品中, 稍后再来哦~',
  'task.lottery.networkErrMsg': 'Network error, please check and try again', //当前网络异常, 请检查网络后重试~',
  'task.lottery.Replenishment':
    'The prizes have been exhausted. We are replenishing the prizes. Come back later.', //奖品被抽光啦, 正在补充奖品中, 稍后再来哦~',
  'task.lottery.balance.lessMsg':
    'Coin is insufficient, hurry up and earn more coins~', //金币余额不足, 快去赚金币吧~',
  'task.lottery.forAWhile':
    'The lucky draw is currently unavailable. Check back later.', //当前无法进行抽奖, 晚点再来看看吧',
  'task.lottery.luckyId.error': 'Wrong winning id: ', //中奖id错误：',
  'task.lottery.luckyId.cannotFind': 'No prize found. The winning id is: ', //没找到奖品, 中奖id为：',
  'task.lottery.interrupt':
    'Oops, the draw was interrupted unexpectedly! Please try again later or contact customer service', //哎呀, 抽奖意外中断了！请稍后重试或联系客服',
  'task.lottery.start.first': 'Please start first', //请先开始',
  'task.lottery.number.error': 'Wrong number', //数字错误',
  'task.lottery.now': 'Now', //立即',
  'task.lottery': 'draw', //抽奖',
  'task.lottery.add': 'Adding', //补充',
  'task.lottery.rewards': 'prizes', //奖品中',
  'task.lottery.draw.congratulate': 'Congratulations! You have won', //恭喜！您抽中了',
  'task.lottery.coin.once': 'Coins/time', //金币/次',
  'task.lottery.draw.view':
    'You can receive it after waiting for the system to issue it, and you can check the progress on the "Welfare-Exchange History" page.', //, 等待系统发放后即可领取, 可在“福利-兑换记录“页查看进度',
  'task.lottery.draw.viewReward':
    'The prize has been sent to your account, you can check it on the "Welfare - Redemption History" page', // , 奖品已发送到您账户, 可在“福利-兑换记录”页查看',
  'task.lottery.draw.physicalGoods':
    'Please contact customer service in time to receive it. You can check the contact information and progress on the "Welfare -Exchange History" page~', // , 请及时联系客服领取, 可在“QuickFox App-福利-兑换记录”页查看联系方式及进度~',
  'task.lottery.rule': 'Lottery rules', //抽奖规则',
  'task.lottery.rulePv': '抽奖规则点击量', //抽奖规则点击量',
  'task.lottery.myRewardPv': '我的奖品点击量', //我的奖品点击量',
  'task.lottery.tab.item1.part1': 'Activity', //活动',
  'task.lottery.tab.item1.part2': 'Rules', //规则',
  'task.lottery.tab.item2.part1': 'My', //我的',
  'task.lottery.tab.item2.part2': 'Prizes', //奖品',
  'task.lottery.coin.earnIt': 'Win coins>>', //赚金币>>',
  'task.lottery.more': 'More lottery', //更多抽奖',
  'task.lottery.result': 'Lottery result', //抽奖结果',
  'task.lottery.again': 'Draw again', //再抽一次',
  'task.lottery.toView': 'View', //前往查看',
  'task.lottery.lickDrawNowPv': '“立即抽奖”按钮成功点击量', //“立即抽奖”按钮成功点击量',
  'task.lottery.error': 'Lottery error: ', //抽奖报错了：',
  'task.lottery.notLogin': 'Log in', //未登录',

  'task.walfare.center': 'Welfare center', //福利中心',
  'task.walfare.info.refresh.done': 'Account information refreshed', //已刷新个人信息',
  'task.walfare.info.refresh.error': 'Failed to refresh account information', //刷新个人信息出错了',
  'task.walfare.refreshed': 'Refreshed', //已刷新',
  'task.walfare.refresh.error': 'Refresh error', //刷新出错了',
  'task.walfare.viewPv': '福利页浏览量', //福利页浏览量',
  'task.walfare.all': 'All', //全部',
  'task.walfare.giftMoney': 'Red envelope', //红包',
  'task.walfare.coin.clickPv': '金币明细点击量', //金币明细点击量',
  'task.walfare.coin.left': 'Coins remained', //剩余金币',
  'task.walfare.coin.mine': 'My coins', //我的金币',
  'task.walfare.coin.expire.thisMonth': 'Coins expires this month', //本月到期金币',
  'task.walfare.coin.expire.time': 'Expire time', //到期时间',
  'task.walfare.coin.earn': 'Win coins', //赚取金币',
  'task.walfare.exchange.record': 'Exchange record', //兑换记录',
  'task.walfare.exchange.recordPv': '兑换记录点击量', //兑换记录点击量',
  'task.walfare.coin.descrip': 'Coin description', //金币说明',
  'task.walfare.coin.descrip.usage': 'How to use coins', //金币有什么用？',
  'task.walfare.coin.descrip.usage.part1':
    'Coins are your virtual currency assets in QuickFox. You can use coins to exchange cash red envelopes, VIP members and other products in the welfare center.', //金币是您在QuickFox的虚拟货币资产, 您可使用金币在福利中心兑换现金红包、超级VIP会员等各种商品。',
  'task.walfare.coin.descrip.channel': 'How to win coins?', //如何获得金币？',
  'task.walfare.coin.descrip.channel.part1':
    'You can get coins by completing tasks such as daily check-in, inviting friends, and follow QuickFox official WeChat account.', //可通过完成每日签到、邀请好友、关注QuickFox公众号等任务获得金币。',
  'task.walfare.coin.descrip.expire': 'Coin expiration instructions: ', //金币到期说明：',
  'task.walfare.coin.descrip.expire.part1':
    'The coins generated every month will expire after 3 months, and the coins that will expire this month will enter the list of expired coins. The coins that are about to expire will be consumed first when exchanging. If not redeemed in time, the coins will expire and cannot be retrieved.', //每月的产生的金币将在3个月后到期，本月将到期的金币将进入到期金币列表中，兑换时优先消耗即将到期金币。若未及时兑换，金币将过期失效，无法找回。',
  'task.walfare.coin.noticePv': '公告点击量', //公告点击量',
  'task.walfare.detail.view': 'Detail', //查看详情',
  'task.walfare.tasks.more': 'More task', //更多任务',
  'task.walfare.tasks.clickPv': '更多任务点击量', //更多任务点击量',

  'task.goods.clickPv': '商品点击量', //商品点击量',

  // 邀请活动2期
  'welfare.invitev2.page.title': 'Give membership',
  'welfare.invitev2.page.getFail': 'Failed to get data',
  'welfare.invitev2.rule.title': 'Giving rules',
  'welfare.invitev2.link.title': 'Free QuickFox Member Card for you', //赠送你一张QuickFox会员卡
  'welfare.invitev2.link.descrip':
    'QuickFox Accelerator for returning to China, accelerating the preferred brand of Chinese returning to China, accelerating one step faster',

  'welfare.invitev2.card.type': 'QuickFox VIP',
  'welfare.invitev2.card.nocheck': 'Unclaimed',
  'welfare.invitev2.card.sendTo': 'Gifted to a friend ends of {account}',

  'welfare.invitev2.msgs.title': 'Leave a message to a friend',
  'welfare.invitev2.msgs.next': 'Change one',

  'welfare.invitev2.btn.allCheck':
    'Membership cards have all been received by friends',
  'welfare.invitev2.btn.giftNow': 'Gift now',
  'welfare.invitev2.btn.timeEnd': 'The gift time has ended',
  'welfare.invitev2.btn.submitAgain': 'Apply to continue gifting',
  'welfare.invitev2.btn.upgradeToVip': 'Upgrade to VIP',
  'welfare.invitev2.btn.leftTime': 'Remaining gift time',
  'welfare.invitev2.btn.copyLink': 'Copy Link',
  'welfare.invitev2.btn.copied': 'Gift link has been copied',
  'welfare.invitev2.modal.cantGift': 'Currently unable to gift members',
  'welfare.invitev2.modal.content':
    'After successfully purchasing a member, you can gift the member to your friend, and your friend can get a QuickFox member after registering~',
  'welfare.invitev2.modal.getIt': 'Got it',
  'welfare.invitev2.modal.buyVip': 'Buy now',

  // 邀请活动2期
  'welfare.invitev2.page.directions':
    'Note: After activating the gift quota, your latest paid order will lose the 7-day unreasonable refund qualification.', //友情提示：激活赠送名额后，您最新的一笔付费订单将失去7天无理由退款资格。',
  // 'welfare.invitev2.rule.content': `
  //   1、即日起，会员剩余时长≥30天的用户可激活并领取3个会员赠送名额（需成功购买过会员，且只可领取一次），并在规定时间内通过链接形式分享给好友，好友完成注册后即可领取30天QuickFox会员；<br/>
  //   2、激活赠送名额后，您最新一笔付费订单将撤销7天无理由退款资格，请在确认已同意该规则后再激活名额；<br/>
  //   3、同一手机号码只能领取一次 15 天会员，无法再次领取；<br/>
  //   4、用户参加活动过程中，如用户存在以所获得的优惠权益进行盈利或非法获利、以任何形式转让或转移其所享有的优惠权益、非本人使用的，或者QuickFox有合理理由怀疑用户存在不当使用权益的，QuickFox将取消用户的权益资格，并有权撤销相关违规交易、收回优惠权益；<br/>
  //   5、如有任何关于本活动的资讯和帮助，请咨询QuickFox在线客服，本活动与苹果公司（Apple Inc.）无关；<br/>
  //   6、在法律范围内，QuickFox对本次活动保留最终解释权；<br/>`,
  'welfare.invitev2.rule.content': `
    1. Users with VIP privileges can activate and receive 3 membership gift quotas every month (must have successfully purchased a membership, and the remaining membership time is more than or equal to 30 days). Share the link with friends within the specified time, and friends can receive the QuickFox membership of the corresponding time after completing the registration; <br/>
    2. Each time you activate a gift quota, your latest paid order will be revoked for 7-day no-reason refund. Please confirm that you have agreed to this rule before activating the quota; <br/>
    3. The same phone number can only receive a 15-day VIP once and cannot be received again; <br/>
    4. During participation in the event, if the user has made profits or illegal profits with the preferential rights and interests obtained, transferred the preferential rights and interests in any form, or used them for non-personal purposes, or QuickFox has reasonable reasons to suspect that the user has improperly used the rights and interests, QuickFox will cancel the user's rights and interests qualification and has the right to cancel the relevant illegal transactions and recover the preferential rights and interests; <br/>
    5. If you have any information and help about this event, please consult QuickFox online customer service. This event is not related to Apple Inc. (Apple Inc.); <br/>
    6. Within the legal scope, QuickFox reserves the final right of interpretation for this event; <br/>`,

  'task.detail.email.title': 'Binding task', //完善账号信息任务',
  'task.detail.email.rule1': 'Rules', //活动规则',
  'task.detail.email.rule2':
    'Users can receive rewards by binding email or phone number', //用户可通过绑定邮箱或手机号领取奖励',
  'task.detail.email.rule3':
    'The reward can only be claimed once and cannot be claimed repeatedly', //本次活动奖励只可领取一次，无法重复领取',
  'task.detail.email.rule4':
    'Users who have bound email addresses or phone numbers can directly claim rewards through this page. Users who register by email or phone number will be automatically issued rewards', //已绑定邮箱、手机号的用户可通过本页面直接领取活动奖励。通过邮箱、手机号注册的用户将自动下发奖励',
  'task.detail.email.rule5':
    'The gift time will be credited in time. The coupon code is sent via email or SMS, and you can only participate through the short URL in the message', //加速时长实时到账。优惠券码通过邮件、短信方式发送，仅可通过信息内的短网址参与',
  'task.detail.email.btn': 'Bind to get', //绑定并领取',
  'task.detail.email.received': 'Has got', //已领取',
  'task.detail.email.finished': 'Event has ended', //活动已结束',
  'task.detail.email.bind': 'Bind now', //立即绑定',
  'task.detail.email.bound': 'Get now', //立即领取',
  'task.detail.email.success': 'Received successfully', //成功领取',
  'task.detail.email.failed': 'Failed to receive', //领取失败',
  'task.detail.email.verify.pleaseEnterCorrectEmail':
    'Please enter the correct email address', //'请输入正确的邮箱地址',
  'task.detail.email.verify.pleaseEnterEmail': 'Please enter the email address', //'请输入邮箱地址',
  'task.detail.email.verify.pleaseEnterCorrectPhone':
    'Please enter the correct phone number', //'请输入正确的手机号码',
  'task.detail.email.verify.pleaseEnterPhone': 'Phone number', //'请输入手机号码',
  'task.detail.email.verify.pleaseEnterCorrectVerifyCode':
    'Please enter the correct verification code', //'请输入正确的验证码',
  'task.detail.email.verify.pleaseEnterVerifyCode': 'Verification code', //'请输入验证码',
  'task.detail.email.verify.pleaseEnterDigitPsw':
    'Please enter a 6-16-digit password with combination of letters and numbers', //'请输入6-16位数字字母组合密码',
  'task.detail.share.title': 'Sharing rewards', //分享有奖',
  'task.detail.share.min5m': 'Please select a picture less large than 5M', //请选择小于5M大小的图片',
  'task.detail.share.noPass': 'Review failed, please resubmit', //审核不通过，重新提交',
  'task.detail.share.submit': 'Submit to review', //提交审核',
  'task.detail.share.underReview': 'Reviewing', //审核中',
  'task.detail.share.pass': 'Approved and rewards issued', //审核通过，已发放奖励',
  'task.detail.share.save': 'Save picture', //保存图片',
  'task.detail.share.cancel': 'Cancel', //取消',
  'task.detail.share.noUpload': 'Please upload screenshots to share', //请先上传分享截图！',
  'task.detail.share.saveSuccess': 'Image has saved to album', //图片已保存至相册',
  'task.detail.share.saveFail': 'Failed to save image!', //保存图片失败！',
  'task.detail.share.successTip': 'Submitted for review, please be patient', //已提交审核，请耐心等待',
  'task.detail.share.version':
    'It is not the latest version. Please bind in "My-My account"', //当前版本较低，请前往“我的-我的账号”页完成绑定',
  'task.detail.share.message': 'Upload image', //请上传图片!',
};
// 邀请裂变第4期
const pointEventV4 = {
  'V4.welfare.center.title': '福利中心',
  'V4.welfare.center.more': '更多福利',
  'V4.welfare.foreignStudent.title': '留学生专属福利',
  'V4.welfare.foreignRule.title': '规则说明',
  'V4.welfare.redeem.norecord.goForIt': 'There is no record yet, go and claim the benefits~',
  
  'V4.welfare.record.title': '福利记录',
  'V4.welfare.miniGame.title': '小游戏任务',
};
const cancel = {
  'cancel.title.reminder': 'Cancellation reminder',
  'cancel.title.auth': 'Authentication',
  // 'cancel.reminder.artical': `
  //  <div  style="color: #90A1DC; ">
  //       注销是不可逆操作，请认真阅读以下重要提醒：
  //     </div>
  //     <p>
  //       1、注销前请确认已解约所有支付平台的连续包月服务， 可查看对应<a href="{url}">解约教程</a>完成解约。为了保障您的权益，未解约无法完成注销；
  //     </p>
  //     <p>
  //       2、帐号申请注销到注销成功有15天的等待期，在等待期内可随时终止注销。等待期结束后帐号将完成注销；
  //     </p>
  //     <p>
  //       3、帐号一旦被注销，所有帐号数据将清空。被注销帐号无法登录、无法找回。其所有权益，包含但不限于剩余会员天数、金币余额等，也将清空无法找回；
  //     </p>
  //     <p>
  //       4、注销后，该帐号关联的手机号、邮箱地址及第三方登陆信息将释放，登陆信息在释放后可以用于其他帐号注册或绑定；
  //     </p>
  //      <p>
  //       5、已注销账号重新注册时，无法获得该账号之前已享受过的新用户福利，包括但不限于新用户超级VIP时长赠送，邀请活动金币奖励等；
  //     </p>
  // `,
  'cancel.reminder.artical': `
   <div  style="color: #90A1DC; ">
        Canceling account is an irreversible operation, please read the following important reminders carefully: 
      </div>
      <p>
        1. Before canceling, please confirm that you have canceled the continuous monthly subscription service of all payment platforms, and you can view the <a href="{url}">corresponding cancellation tutorial</a> to complete the cancellation. In order to protect your rights and interests, the cancellation cannot be completed without canceling the contract; 
      </p>
      <p>
        2. There is a 15-day waiting period before account cancellation completed, and cancellation can be terminated at any time during the waiting period. After the waiting period is over, the account will be canceled; 
      </p>
      <p>
        3. Once the account is canceled, all account data will be cleared. The canceled account cannot log in and cannot be retrieved. All its benefits, including but not limited to remaining membership days, coin balance, etc., will also be emptied and cannot be retrieved; 
      </p>
      <p>
        4. After cancellation, the phone number, email address and third-party login information associated with the account will be released, and the login information can be used to register or bind other accounts after release; 
      </p>
       <p>
        5. When a canceled account is re-registered, the new user benefits that the account has enjoyed before cannot be obtained, including but not limited to the VIP time for new users, coin rewards for invitation activities, etc;
      </p>
  `,
  'cancel.reminder.agree': 'Read and agreed to the QuickFox ', //已阅读并同意：QuickFox ',
  'cancel.reminder.agreement': 'Account cancellation agreement', //'帐号注销协议',
  'cancel.reminder.next': 'Next', //'下一步',
  'cancel.reminder.read': 'Please read the above reminder carefully', //'请认真阅读上述提醒',
  'cancel.reminder.cancelSign':
    'The continuous monthly subscription service of {signTypeText} has not been canceled at present, please confirm the cancellation before proceeding with the cancellation operation', //当前尚未解约{signTypeText}连续包月服务，请确认完成解约后再进行注销操作',
  'cancel.auth.input': 'Please enter the password to complete the verification', //请输入当前账号密码完成验证',
  'cancel.auth.confirm': 'Confirm cancellation',
  'cancel.auth.tips':
    'After submitting the cancellation, you will have a 15-day waiting period, and you can log in again at any time during the waiting period to terminate the cancellation.', //提交注销后，您将有15天注销等待期，等待期内可随时重新登录以终止注销。',
  'cancel.auth.pwd': 'Please enter password',
  'cancel.auth.relogin.third':
    'Please log in to any third-party account again to complete the verification', //请重新登录任一第三方账号以完成验证',
  'cancel.auth.relogin': 'Log in again', //'重新登录',
  'cancel.auth.please': 'Please', //请',
  'cancel.auth.finish': 'Verification completed', //'已完成验证',
  'cancel.auth.unDetected':
    'No third-party account detected, please try to contact customer service', //未检测到第三方账号，请尝试联系客服处理',
  'cancel.auth.inconsistent':
    'This third-party account is inconsistent with the currently bound one, please try again', //本次登录第三方帐号与当前绑定第三方账号不一致，请重试',
  'cancel.auth.failed': 'Third-party account verification failed', //'第三方账号校验失败',
  'cancel.auth.failed.accident': 'Verification failed', //'验证失败',
  'cancel.wx.pay': 'Manage auto-renewal', //管理自动续费',
  'cancel.tips.userInfoFailed':
    'User information not obtained, please try again later', //'未获取到用户信息，请稍后重试',
  'cancel.tips.unexpectedError': 'An unexpected error occurred', //'发生意外的错误',
  'cancel.tips.failToCheckSignStatus': 'Failed to check signing status', //查询签约状态失败
};
const memberi = {
  'memberi.title': 'Redemption Code', //兑换QuickFox超级VIP',
  'memberi.valicode': 'Please enter the redeem code', //请输入兑换码',
  'memberi.loginTip': 'Please log in to exchange', //登录后才可以兑换哦',
  'memberi.loginBtn': 'Login/Register', //登录/注册',
  'memberi.placeholder': 'Enter the redemption code, case sensitivie', //请输入兑换码,注意区分大小写',
  'memberi.getcode': 'Exchange now', //立即兑换',
  'memberi.descTitle': 'Redeem rules', //兑换规则',
  'memberi.desc1':
    'The redemption code is a QuickFox exclusive welfare product. Please do not sell the redemption code or engage in any other commercial activities. If violated, QuickFox reserves the right to remove the membership time', //兑换码可通过参与QuickFox或合作商家相关活动获得',
  'memberi.desc2':
    'After successful redemption, you can get the corresponding VIP time', //兑换成功即可获得对应的超级VIP时长',
  'memberi.desc3':
    'Users who already have VIP membership time and users who are still in the contract status will have their VIP time extended after successful redemption', //已拥有超级VIP会员时长的用户，及仍在签约状态中的用户，将在兑换成功后顺延超级VIP时长',
  'memberi.desc4':
    'QuickFox has the right to interpret these rules within the scope of the law. If you have any questions, please contact the online customer service', //QuickFox在法律规定的范围内享有本规则的解释权。如有疑问，请咨询联系在线客服',
  'memberi.success': 'Exchange successfully', //兑换成功',
  'memberi.fail': 'Exchange failed', //兑换失败,
  'memberi.success1': 'Have get', //已获得',
  'memberi.ok': 'OK', //好的',
  'memberi.blacklistTips': 'Your account is in risk and can not redeem', // '当前帐号存在风险，无法使用兑换码',
};

const customizedMembership = {
  'customizedMembership.viptimer': 'Time remains', //时长剩余',
  'customizedMembership.concat': 'Consult customer service now', //立即咨询客服，获得独享定制线路',
  'customizedMembership.desc1': 'Exclusive bandwidth guarantee', //独享带宽保障',
  'customizedMembership.desc2': 'Watch 4K media', //4K影音秒开',
  'customizedMembership.desc3': 'Smooth live stream', //流畅开播',
  'customizedMembership.desc4': 'Stable main/spare lines', //主备双线双倍稳定',
  'customizedMembership.desc5': '3 concurrent devices', //三设备同时在线',
  'customizedMembership.desc6': 'Multi-platform supported', //全客户端支持',
  'customizedMembership.btn': 'Consult renewal', //咨询续费',
  'customizedMembership.btn1': 'Consult purchase', //咨询购买',
  'customizedMembership.title': 'Customized line',
};

const sportsQuiz = {
  'sportsQuiz.title': 'Match details', //赛事详情',
  'sportsQuiz.shareAndGetCoins': 'Share now to get coins', //快速分享赚金币',
  'sportsQuiz.how2play': 'How to play', //玩法介绍',
  'sportsQuiz.rule': '', //玩转世界杯活动规则',
  'sportsQuiz.guessed': '', //您已竞猜',
  'sportsQuiz.advance': 'Advance', //晋级',
  'sportsQuiz.win': 'Win', //获胜',
  'sportsQuiz.draw': 'Draw', //平局',
  'sportsQuiz.guessWithCoins': 'Guess with {coins} coins', //使用{coins}金币竞猜',
  'sportsQuiz.ifGuessRight': '', //竞猜正确即可获得',
  'sportsQuiz.includes': '', //比赛结果包含加时赛，点球大战',
  'sportsQuiz.leftTime': '', //剩余竞猜时间',
  'sportsQuiz.leftQuota': '', //剩余参与名额',
  'sportsQuiz.end': '', //本场赛事竞猜已结束',
  'sportsQuiz.others': '', //请关注其他场次比赛~',
  'sportsQuiz.getFromNotice': '', //（通过消息中心领取）',
  'sportsQuiz.youHaveGuessed': '', //您已参与竞猜',
  'sportsQuiz.ifThen': '', //若 {predict}{result}您可获得 ',
  'sportsQuiz.guessedRight': '', //恭喜竞猜正确，您的 ',
  'sportsQuiz.goGet': '', // 已发放，快去消息中心领取吧～',
  'sportsQuiz.yourGuess': '', //您的竞猜为：',
  'sportsQuiz.guessedWrong': '', //竞猜错误，下次继续加油哦~',
  'sportsQuiz.goGetCoins': '', //去赚金币',
  'sportsQuiz.platforms': '', //您可以前往以下直播平台观战',
  'sportsQuiz.useQF':
    'Use Quickfox media mode to unlock restrictions with one click', //使用Quickfox影音模式，一键解锁版权限制',
  'sportsQuiz.cctv': 'CCTV', //央视影音',
  'sportsQuiz.yangshipin': 'Yangshipin', //央视频',
  'sportsQuiz.migu': 'Migu', //咪咕视频',
  'sportsQuiz.tiktok': 'Douyin', //抖音',
};

const device = {
  'device.title': 'Online devices', //已登录设备',
  'device.dialog.title': 'Log out', //退出登录',
  'device.dialog.centent':
    'After logging out, the device needs to verify the account password to log in again. Are you sure you want to log out?', //退出登录后该设备再次登录需要验证账号密码，您确定要退出吗',
  'device.dialog.ok': 'Confirm', //确定',
  'device.dialog.cancel': 'Cancel', //取消',
  'applyRecord.title': 'Application history', //申请记录',
  'applyRecord.waiting': 'Pending', //待处理',
  'applyRecord.using': 'Resolve', //已上架',
  'applyRecord.exitFailed': 'Rejected', //已拒绝',
  'device.thisDevice': 'This device', //本机
  'device.exit': 'Exit', //退出
  'device.offline': 'Offline', //下线
  'device.exitSuccess': 'Exit successfully', //'退出成功',
};

// 绑定邮箱活动
const bindEmail = {
  'bindEmail.title': 'Bind email address',
  'bindEmail.success.tip': 'Binding succeeds!',
  'bindEmail.areaCode': 'Area code',
  'bindEmail.phoneEmpty': 'Phone number',
  'bindEmail.emailEmpty': 'email address',
  'bindEmail.switchTo': 'Switch to',
  'bindEmail.getCode': 'Get verification code',
  'bindEmail.findCountry': 'Please search with country or area code',
  'bindEmail.emailVerify': 'Please enter the correct email address',
  'bindEmail.phoneVerify': 'Please enter the correct phone number',
  'bindEmail.codeVerify': 'Please enter the correct verification code',
  'bindEmail.pwsVerify':
    'Please input 6-16 digits combination of letters and numbers',
  'bindEmail.pwsEmpty': 'Password',
  'bindEmail.codeEmpty': 'SMS Code',
  'bindEmail.phone': 'Phone',
  'bindEmail.email': 'Email',
};

// 任务补充
const taskSupplement = {
  'taskSupplement.redemptionDetails': 'Exchange detail page',
  'taskSupplement.missionRules': 'rules',
  'taskSupplement.shareScreenshot': 'Select screenshots to share',
  'taskSupplement.supportChannel':
    'Supports screenshots on Xiaohongshu,WeChat,Weibo,Douyin,etc.',
  'taskSupplement.advisoryService':
    'Please abide by the mission rules, if you have any questions, please consult customer service',
  'taskSupplement.auditPrompt1':
    'Please keep this shared content visible during the review period',
  'taskSupplement.auditPrompt2':
    'After the review is passed, the coin reward will be issued automatically, please wait patiently',
  'taskSupplement.sharePoster': 'share poster',
  'taskSupplement.saveImg': 'Long press the picture to save',
  'taskSupplement.rulesDesc4':
    'From now on, all registered users can upload and share screenshots with QuickFox sharing posters on social platforms (including Xiaohongshu, WeChat, Weibo, Douyin, Facebook, Twitter, Instagram, Tiktok, etc.) on the task page, and 100 gold coins reward will be issued after review is approved',
  'taskSupplement.rulesDesc5':
    'Each user can complete a task once. During the task review period, please keep the shared content visible on social platforms, otherwise the review may fail',
  'taskSupplement.rulesDesc6':
    'The review will be completed within 2 working days, please wait patiently',
  'taskSupplement.rulesDesc7':
    "During the user's participation in marketing activities, if the user makes profit or illegal profit with the obtained preferential rights, transfers the preferential rights in any form, does not use them himself, or QuickFox has reasonable grounds to suspect that the user exists In case of improper use of discount tools or privileges, QuickFox will disqualify users for their privileges, and has the right to revoke relevant illegal transactions and withdraw privileges (including used and unused ones)",
  'taskSupplement.rulesDesc8':
    'If you have any information about this event, please consult our online customer service. This event has nothing to do with Apple Inc.',
  'taskSupplement.rulesDesc9':
    'Within the scope of the law, QuickFox reserves the right of final interpretation of this event',
  'taskSupplement.xiecheng': 'Xiecheng page',
};

// 微信续费补充
const wxPayManage = {
  'wxPayManage.activatedBusiness': 'Activated business',
  'wxPayManage.weChatPay': 'Wechat Pay',
  'wxPayManage.continuousMonthlyMembership': 'Continuous monthly membership',
  'wxPayManage.nextRenewalTime': 'Next renewal time',
  'wxPayManage.stopService': 'Stop service',
  'wxPayManage.successfullyUnsubscribe':
    "Successfully unsubscribe from WeChat's continuous monthly subscription",
};

// 支付宝实名认证
const zfbManage = {
  'zfbManage.cancelledVerification': 'User has cancelled verification',
  'zfbManage.enterNumber':
    'Please enter the correct x-digit ID number,Please enter the correct 18-digit ID number,',
  'zfbManage.enterX': 'If the ID contains letters at the end, please enter X',
  'zfbManage.enterID': 'Please enter the ID number registered on the ID card',
  'zfbManage.enterName': 'Please enter the real name registered on the ID card',
  'zfbManage.verificationFailed': 'Verification failed',
  'zfbManage.getCode': 'Please go back to App and get QR code again',
  'zfbManage.realNameverify': '身份校验',
  'zfbManage.successed':
    '恭喜您完成身份校验',
  'zfbManage.complete': 'Complete',
  'zfbManage.callChatTextPrefix': '非中华人民共和国居民身份用户，可',
  'zfbManage.callChatText': '联系客服',
  'zfbManage.callChatTextSuffix': '完成校验',
  'zfbManage.authentication': '身份校验使用条款',
  'zfbManage.realNameVerifyDesc1': `为保障您的账号安全，请完成身份校验。`,
  'zfbManage.realNameVerifyDesc2':
    '当前身份校验服务由“支付宝”提供，请您提前安装并认证相关应用。',
  'zfbManage.realNameVerifyDesc3':
    '您提交的身份信息仅用作身份校验使用，QuickFox承诺不将其应用于任何其他场景，更不会透露给其他第三方。',
  'zfbManage.qRealNameverify': 'QuickFox身份校验',
  'zfbManage.enterRealName': 'Please enter your real name of the ID',
  'zfbManage.realName': 'Real name',
  'zfbManage.enterRealID': 'Please enter your 18-digit ID number',
  'zfbManage.IDCode': 'ID number',
  'zfbManage.now': '立即校验',
  'zfbManage.go': '前往认证中',
  'zfbManage.upgrade':
    'Current client version does not support this request, please upgrade first',
  'zfbManage.failed': 'Loading failed',
  'zfbManage.sendData': 'Decrypting sent data',
};

const blacklistBlocking = {
  'blacklist.youRisk': 'Your account is in risk', //'您的帐号存在风险',
  'blacklist.cannot': 'Can not participate in welfare tasks', //'暂无法参与福利中心任务',
  'blacklist.sendEmail':
    'If you have any objection, please contact email address info@quickfox.com.cn to appeal', // '如有异议，请邮件联系info@quickfox.com.cn进行申诉',
  'vip.cantuse': 'Enterprise sub-accounts cannot perform this operation'
};

const FraudBlacklistBlocking = {
  'blacklist.policy': 'Due to policy reasons',
  'blacklist.policyDetail': 'services are no longer available in your country or region',
  'blacklist.thanks': 'Thank you for your understanding and support!',
};

const zh_CN = {
  ...about,
  ...contact,
  ...help,
  ...helpDetail,
  ...memberServiceAgreement,
  ...notice,
  ...tsupport,
  ...center,
  ...Welfare,
  ...PageTitle,
  ...Common,
  ...Tips,
  ...device,
  ...sportsQuiz,
  ...bindEmail,
  ...taskSupplement,
  ...wxPayManage,
  ...zfbManage,
  ...customizedMembership,
  ...memberi,
  ...cancel,
  ...taskCenterV3,
  ...pointEventV4,
  ...pointEventEnd,
  ...tutorial,
  ...TimeType,
  ...ActivationRecord,
  ...monthlyVipAgreement,
  ...http,
  ...blacklistBlocking,
  ...FraudBlacklistBlocking,
};

export default zh_CN;
