import memberAgreementTxt from './memberAgreementTxt';
import monthlyVipAgreementTxt from './monthlyVipAgreementTxt';

// 关于我们
const about = {
  'about.tab.title': 'Về chúng tôi', //'关于我们',
  'about.welcome': 'Chào mừng đến với QuickFox', //欢迎来到QuickFox`,
  'about.list.check': 'Kiem tra cap nhat',//'检查更新',
  'about.list.contact': 'Liên lạc chúng tôi', //'联系我们',
  'about.list.like': 'Đánh giá tuyệt vời', //给个好评',
  'about.list.terms': 'Điều khoản dịch vụ', //'服务条款',
  'about.list.policy': 'Thỏa thuận ẩn ý', //'隐私协议',
  'about.list.vip': 'Thỏa thuận dịch vụ thành viên', //'会员服务协议',
};

// 联系我们
const contact = {
  'contact.tab.title': 'Liên lạc chúng tôi', //'联系我们',
  'contact.title':
    'Bạn có thể liên lạc với chúng tôi bằng phương thức dưới đây', //您可以通过以下方式和我们取得联系',
  'contact.list.offcial': 'Website chính thức', //官方网站',
  'contact.list.wx': 'Tài khoản công khai Wechat', //'微信公众号',
  'contact.list.qq': 'QQ DVKH', //客服QQ',
  'contact.list.qqgroup': 'Nhóm QQ chính thức', //官方QQ群',
  'contact.list.wxkf': 'Hỗ trợ kỹ thuật Wechat', //技术支持微信',
  'contact.list.email': 'Email', //邮箱',
  'contact.list.op.visit': 'Truy cập', //去访问',
  'contact.list.op.copy': 'Sao chép', //复制号码',
  'contact.list.op.copyAccount': 'Sao chép', //复制帐号',
  'contact.list.op.copy.mail': 'Sao chép', //复制地址',
  'contact.copy': 'Sao chép thành công', //'复制成功',
};

// 帮助
const help = {
  'help.tab.title': 'Trợ giúp', //'帮助',
  'help.title': 'Cần hỗ trợ nhân tạo không? Nhân để liên lạc DVKH trực tuyến', //需要人工帮助？点击联系在线客服',
  'help.contact': 'Liên hệ', //立即联系',
  'help.head.speedUp': 'Tăng tốc độ', //加速',
  'help.head.recharge': 'Nạp tiền', //充值',
  'help.head.other': 'Khác', //其他',
  'help.pv': '帮助页浏览量', //帮助页浏览量',
  'help.speed': 'Tăng tốc độ', //加速',
  'help.other': 'Khác', //其他',
  'help.vip': 'Thành viên', //会员',
};

// 帮助详情
const helpDetail = {
  'helpDetail.tab.title': 'Chi tiết', //详情',
  'helpDetail.about': 'Vấn đề có liên quan', //相关问题',
};

const notice = {
  'notice.tab.title': 'Trung tâm tin nhắn', //消息中心',
  'notice.nodata': 'Không có tin nhắn', //暂无任何消息哦~',
  'notice.numberOfMsgJumpClick': '站内信按钮跳转到会员页的点击量', //站内信按钮跳转到会员页的点击量',
  'notice.getItRightnow': 'Nhấn để nhận', //点击领取',
  'notice.allReadyGetIt': 'Đã nhận', //已领取',
  'notice.getItSuccessfully': 'Nhận thành công', //领取成功',
  'notice.rechargeNow': 'Nạp tiền ngay', // '立即充值',
};

// 技术支持
const tsupport = {
  'tsupport.title': 'Hỗ trợ kỹ thuật',
  'tsupport.wechatDesc': 'QuickFox Hỗ trợ kỹ thuật',
  'tsupport.addService': 'Liên hệ với chúng tôi',
  'tsupport.tip': 'Nếu bạn gặp các vấn đề như crash, không thể tăng tốc, tăng tốc chậm trễ, bị đơ v.v... trong quá trình sử dụng Vui lòng liên hệ với chúng tôi.',
  'tsupport.unSupport': 'Tạm thời không hỗ trợ',
};

// Http
const http = {
  'http.prompt.networkError': 'Lỗi mạng', //网络错误',
};

const memberServiceAgreement = {
  'memberServiceAgreement.tab.title': 'Thỏa thuận dịch vụ thành viên', //会员服务协议',
  'memberServiceAgreement.content': memberAgreementTxt,
};

const monthlyVipAgreement = {
  'monthlyVipAgreement.tab.title': 'Thỏa thuận gói tháng liên tục', //连续包月协议',
  'monthlyVipAgreement.content': monthlyVipAgreementTxt,
};

const Welfare = {
  'welfare.header.todayincome': 'Lãi hôm nay', //今日收益',
  'welfare.header.totalincome': 'Thu nhập lũy kế', //累计收益',

  'welfare.coinsTasks': 'Nhiệm vụ đồng xu', //金币任务',

  'welfare.tasksCard.chekout': 'Tìm kiếm', //查看',
  'welfare.tasksCard.coins': 'Xu vàng', //金币',

  'welfare.tasks.invite': 'Mời có quà', //邀请有礼',
  'welfare.tasks.invite.unit': 'Xu vàng/người', //金币/人',
  'welfare.tasks.invite.desc': 'Mời thành viên kiếm thẻ thành viên năm', //邀请赚年卡会员',
  'welfare.tasks.invite.descript': 'Mời bạn bè để kiếm hồng bao chụp triệu', //邀请好友拿金币, 百万红包等你兑',
  'welfare.tasks.sign': 'Đánh dấu hàng ngày', //每日签到',
  'welfare.tasks.sign.descript': 'Tối cao nhận 50 xu vàng mỗi ngày', //最高每日可获得50金币哦',
  'welfare.tasks.wx': 'Theo dõi tài khoản công khai', //关注公众号',
  'welfare.tasks.bindEmail': 'Liên kết nhận quả tặng', //绑定有礼',
  'welfare.tasks.bindText': 'Đi liên kết', //去绑定',
  'welfare.tasks.bindEmail.descript':
    'Liên kết email hoặc số điện thoại để nhận thời gian tăng tốc thành viên và xu vàng', //绑定邮箱或手机号即可领取会员加速时长及金币',
  'welfare.tasks.bindEmail.desc': '+Thời hạn VIP 6h', //+6小时VIP时长',
  'welfare.tasks.bindEmail.bindTimes': '+Thời hạn VIP 6h', //+6小时VIP',
  'welfare.tasks.share': 'chia sẻ', //分享有奖',
  'welfare.tasks.share.descript':
    'Chia sẻ áp phích trên nền tảng mạng xã hội, tải lên màn hình chụp có thể nhận 1 xu vàng', //在社交平台分享海报，截图上传领取100金币',
  'welfare.tasks.wx.descript':
    'Theo dõi tài khoản chính thức Wechat QuickFox để nhận 30 xu vàng', //关注QuickFox微信公众号即可领取30金币',
  //签到页面
  'welfare.sign.dayno': 'Ngày {day}', //第 {day} 天',
  'welfare.sign.vipTime': 'VIP {vipTime}{vipTimeType}',
  'welfare.sign.day': 'Ngày', //天',
  'welfare.sign.hadSign': 'Đã liên tục đăng ký', //已连续签到',
  'welfare.sign.sign': 'Đăng ký', //签到',
  'welfare.sign.signed': 'Đã đăng ký', //已签到',
  'welfare.sign.tips': 'Đăng ký liên tục để nhận phần thưởng phong phú nhé~', //连续签到可获得丰厚奖励哦~',
  'welfare.sign.successText': 'Đăng ký thành công', //签到成功',
  'welfare.sign.rule.title': 'luật lệ', //签到细则',
  'welfare.sign.rule.modalTitle': 'Chi tiết đăng ký',
  'welfare.sign.rule.eventTime': 'Thời gian: 24-12-2024 12:00 đến 2025-1-2 18:00',
  'welfare.sign.rule.part1': 'Đăng nhập {signCount}{signTimeType} liên tiếp để nhận VIP {rewardVipTime} {rewardTimeType};',
  'welfare.sign.rule.part2': 'Đăng nhập {signCount}{signTimeType} liên tiếp để nhận VIP {rewardVipTime} {rewardTimeType};',
  'welfare.sign.rule.part3': 'Gián đoạn đăng nhập sẽ được tính ngày kể từ khi bắt đầu;',
  'welfare.sign.rule.part4': 'Phần thưởng đăng nhập sẽ không được ghi lại nếu đăng ký cùng một thiết bị. Nghiêm cấm nhận phần thưởng đăng nhập bằng các phương tiện không phù hợp;',
  'welfare.sign.rule.interpretation': 'Quyền giải thích cuối cùng về hoạt động này thuộc về QuickFox;',
  'welfare.sign.successTips':
    'Đăng ký thành công đã nhận được {todayReward} xu vàng Hãy nhớ ngày nào cũng đến đây nhé', //签到成功, {todayReward} 金币到手, 记得每天都来哦~',
  'welfare.sign.signSuccess':
    'Đăng nhập thành công~',
  'welfare.sign.signSuccessVip':
    'Đăng nhập thành công, đã nhận được {vipTime} {timeType} VIP, hãy nhớ đến mỗi ngày nhé~',
  'welfare.sign.ruleDetail':
    'Đăng ký hằng ngày để nhận 2 xu vàng Đăng ký liên tục thành công trong 7 ngày sẽ nhận được phần thưởng xu vàng quá đáng Đăng ký trong ngày đó tối cao có thể nhận 50 xu vàng Hãy nhớ ngày nào cũng đến đây nhé~', //每日打卡签到可获得2金币, 每成功连续签到7天可获得额外金币奖励, 最高当日签到可获得50金币, 记得每天都来哦~',
  'welfare.sign.ruleDetail2':
    'Nếu đăng ký nhiều lần trên cùng một thiết bị sẽ không được ghi nhận xu vàng thưởng, cấm lấy xu vàng thưởng bằng cách không chính đáng, QuickFox có quyền thu hồi xu vàng thưởng và đóng đơn hàng đổi', //同一设备多次注册不记录奖励金币，禁止通过不正当手段获取金币奖励，QuickFox有权收回金币奖励及关闭兑换订单',
  //关注公众号页面

  'welfare.wx.follow': 'Theo dõi tài khoản công khai', //关注公众号',
  'welfare.wx.welfareForInvite': 'Mời có quà', //邀请有礼',
  'welfare.wx.signIn': 'Chia sẻ để nhận thưởng', //分享有奖',
  'welfare.wx.toSignIn': 'Đi đăng ký', //去签到',
  'welfare.wx.toFollow': 'Đi theo dõi', //去关注',
  'welfare.wx.toInvite': 'Đi mời', //去邀请',
  'welfare.wx.toGet': 'có thể nhận', //可得',
  'welfare.wx.toGetHighest': 'Tối đa nhận được', //最高获得',

  'welfare.wx.qrcodeTip.saved': 'Mã QR đã lưu vào Album', //二维码已保存至相册',
  'welfare.wx.qrcodeTip.failed': 'Lỗi lưu mã QR', //二维码保存失败了',
  'welfare.wx.qrcodeTip.version': 'Tải phiên bản mới nhất để lưu mã QR!', //保存二维码需要下载最新版本!',
  'welfare.wx.Intro.title': 'Giới thiệu nhiệm vụ', //任务介绍',
  'welfare.wx.Intro.step': 'Bước', //步骤',
  'welfare.wx.Intro.follow': 'Theo dõi', //关注',
  'welfare.wx.Intro.reward': 'Phần thưởng', //奖励
  'welfare.wx.Intro.rewardText1.part1': 'Theo dõi chúng tôi để nhận được ',
  'welfare.wx.Intro.rewardText1.part2': '{vipCount} giờ thời gian VIP',
  'welfare.wx.Intro.pc.scanit': 'Quét hình ảnh sau bằng "Quét" Wechat', //使用手机微信“扫一扫”下方图片',
  'welfare.wx.Intro.mobile.scanit':
    'Lưu mã QR vào Album Mở Wechat chọn "Quét" hình này', //保存下方二维码至相册, 打开微信, 选择“扫一扫” 该图',
  'welfare.wx.Intro.follow.descrip':
    'Nhấn nút "Theo dõi" ở gói trên phải có thể hoàn thành nhiệm vụ', //点击右上角“关注”按钮, 即可完成任务',

  //邀请页面
  'welfare.invite.rule.title': 'Luật mời', //邀请规则',
  'welfare.invite.personNum': 'Đã mời thành công (người)', //已成功邀请 (人)',
  'welfare.invite.viewDetail': 'Xem chi tiết', //查看明细',
  'welfare.invite.inviteDetail': 'Xem chi tiết', //邀请明细',
  'welfare.invite.coinNum': 'Phần thưởng mời (xu vàng)', //邀请奖励 (金币)',
  'welfare.invite.dayNum': 'Phần thưởng mời (ngày)', //邀请奖励 (天)',
  'welfare.invite.duration': 'Thời gian nhận (ngày)', //获赠时长 (天)',
  'welfare.invite.toGoods': 'Đổi xu vàng', //金币兑换',
  'welfare.invite.rewardDetail': 'Chi tiết phần thưởng', //奖励明细
  'welfare.invite.face2face': '海报&面对面邀请点击量', //海报&面对面邀请点击量',
  'welfare.invite.copyLink': 'Sao chép liên kết mời', //复制邀请链接',
  'welfare.invite.post.saved': 'Áp phích đã được lưu vào album ảnh', //海报已保存至相册',
  'welfare.invite.post.failed': 'Lưu áp phích không thành công', //海报保存失败了',
  'welfare.invite.post.descrip.tips':
    'Lưu áp phích hoặc ảnh chụp màn hình để chia sẻ cho bạn bè Mời bạn bè quét mã QR Bạn và bạn bè sẽ nhận được 100 xu vàng sau khi mời thành công', //保存海报或截屏分享给好友, 邀请好友扫描二维码, 成功邀请后你和好友各得100金币',
  'welfare.invite.post.version':
    ' Bạn cần tải phiên bản mới nhất để lưu áp phích', //保存海报需要下载最新版本!',
  'welfare.invite.post.pcAppTip': 'Đến phía QuickFox APP lưu áp phích', //请前往QuickFox APP端保存海报!',
  'welfare.invite.post.saveBtn': 'Lưu áp phích', //保存海报',
  'welfare.invite.post.descrip.regist':
    'Quét mã để đăng ký <br /> tài khoản QuickFox', // 扫码注册 <br /> QuickFox账号`,
  'welfare.invite.post.descrip.speedOnce':
    'Tải xuống APP <br /> sử dụng tăng tốc một lần', //下载APP <br /> 使用一次加速`,
  'welfare.invite.post.descrip.getCoins':
    'Bạn và bạn bè <br /> Đều sẽ nhận được 100 xu vàng', //您和好友 <br /> 各获得100金币`,
  'welfare.invite.process.regist': 'Bạn bè nhấn Liên kết mời <br /> để đăng ký', // 好友点击 <br /> 邀请链接注册`,
  'welfare.invite.process.speedOnce':
    'Tải xuống APP <br /> sử dụng tăng tốc một lần', //下载APP <br /> 使用一次加速`,
  'welfare.invite.process.getCoins':
    'Bạn và bạn bè <br /> Đều sẽ nhận được 100 xu vàng', //您和好友 <br /> 各获得100金币`,
  'welfare.invite.process.tips':
    'Bạn bè cần đăng nhập vào tài khoản đã được đăng ký trong liên kết mời trên một thiết bị di động mới (tức là các số điện thoại chưa đăng nhập vào tài khoản QuickFox trước đây) Sau khi sử dụng một lần tăng tốc thì có thể nhận phần thưởng', //*好友需在新移动设备上（即之前未登录过QuickFox帐号的手机）登录在邀请链接上注册的帐号, 使用一次加速后方可获得奖励。',

  'welfare.invite.link.title': 'Bạn có siêu VIP chưa nhận', //你有超级VIP待领取',
  'welfare.invite.link.descrip':
    'Ứng dụng tăng tốc QuickFox có phúc lợi, Đăng nhập để nhận siêu VIP, Có nhiều cơ hội giành hồng bao, tiền mặt', //QuickFox加速器发放福利啦, 注册即送超级VIP, 更有现金红包等你来抢！',
  'welfare.invite.historyBtn': 'Chi tiết lời mời', // '邀请明细',
};

const PageTitle = {
  'pageTitle.goods': 'Cửa hàng', //商城',
  'pageTitle.welfare': 'Phúc lợi', //'福利',
  'pageTitle.invite.detail': 'Chi tiết mời', //邀请明细',
  'pageTitle.taskDetails': 'Theo chúng tôi', //关注公众号',
  'pageTitle.inviteDetails': 'Mời có quà', //邀请有礼',
  'pageTitle.taskSignin': 'Đánh dấu hàng ngày', //每日签到',
  'pageTitle.taskCenter': 'Trung tâm nhiệm vụ', //任务中心',
  'pageTitle.taskEmail': 'Liên kết email', //绑定邮箱',
  'pageTitle.taskShare': 'Chia sẻ để nhận thưởng', //分享有奖',
  'pageTitle.appApplyRecord': 'Lịch sử đăng ký',
};

const Common = {
  'common.ok': 'Vâng', //好的
  'common.confirm': 'Xác định', //确定
  'common.cancel': 'Hủy bỏ', //取消
  'common.avatar': 'Hình đại diện', //头像',
  'common.iSee': 'Tôi đã biết', //我知道了',
  'common.human': 'Người', //人',
  'common.requreUpdate':
    'Phiên bản này quá thấp Vui lòng đăng nhập đến phiên bản mới nhất', //当前版本过旧, 请更新至最新版本',
  'common.currency.dollar': 'Đôla mỹ', //美元',
  'common.free': 'Miễn phí', //免费',
  'common.currency.rmb': 'tệ', //元',
  'common.init': 'Đang khởi tạo',
  'common.payType.offline': 'Thanh toán ngoại tuyến', //'线下支付',
  'common.payType.alipay': 'Alipay', //'支付宝',
  'common.payType.wxMiniProg': 'Chương trình nhỏ Wechat', //'微信小程序',
  'common.payType.wx': 'Thanh toán bằng Wechat', //微信支付
  'common.payType.bank': 'Thẻ ngân hàng/thẻ tín dụng', //银行卡/信用卡
};

const Tips = {
  'tips.pleaselogin': 'Vui lòng đăng nhập trước', //请先登录',
  'tips.pleaselogin.event': 'Đăng nhập sau mới có thể tham gia hoạt động~', //登录后才可以参加活动哦~',
  'tips.gologin': 'Đi đăng nhập', //去登录',
  'tips.loadFailed': 'Không tải được', //加载失败',
  'tips.listLoadFailed': 'Tải danh sách không thành công', //加载列表失败',
  'tips.loadFailed.retry': 'Không tải được, vui lòng thử lại sau', //加载失败, 请稍后重试',
  'tips.refreshed': 'Đã làm mới', //已刷新',
  'tips.refreshFailed': 'Lỗi làm mới', //刷新出错了',
  'tips.saving': 'Đang lưu...', //保存中...',
  'tips.saveQrcode': 'Lưu mã QR', //保存二维码',
  'tips.copyed': 'Sao chép thành công', //复制成功',
  'tips.loadingHard': 'Đang tải...', //拼命加载中, 请稍候...',
  'tips.loading': 'Đang tải...', //加载中
  'tips.nodata.forNow': 'Tạm thời không có dữ liệu', //暂无数据',
  'tips.load.success': 'Tải xong', //加载完成
  'tips.goingToPay': 'Đang đi thanh toán...', //正在前往支付...',
  'tips.cancelSuccessful': 'Hủy bỏ thành công', //取消成功',
  'tips.failToRefreshOrder': 'Làm mới đơn đặt hàng không thành công', //订单刷新失败',
  'tips.refreshOrderError': 'Lỗi làm mới đơn đặt hàng', //订单刷新出错了',
  'tips.refreshListError': 'Lỗi làm mới danh sách', //刷新列表出错了',
  'tips.refreshListDone': 'Đã làm mới danh sách', //已刷新列表',
  'tips.pull2Refresh': 'Kéo xuống để cập nhật', // '下拉可以刷新',
  'tips.release2Refresh': 'Thả để cập nhật ngay', // '释放立即刷新',
  'tips.refreshing': 'Đang cập nhật', //'正在刷新',
};
// 开通记录
const ActivationRecord = {
  'record.records': 'Nhật ký mở', //开通记录',
  'record.options': 'Điều hành', //'操作',
  'record.memberRefund': 'VIP Đền bù', //会员退款',
  'record.syncOrder': 'Đồng bộ', //'同步订单',
  'record.tip': 'Nhắc nhở', //温馨提示',
  'record.tipDesc':
    'Bạn chắc chắn muốn hủy dịch vụ đăng ký liên tục QuickFox siêu VIP không? Điều này sẽ làm cho tài khoản của bạn không được gia hạn, sau khi hết hạn sẽ không thể sử dụng đường truyền riêng VIP nữa', //您确定要关闭QuickFox超级VIP连续包月服务吗？这将导致您的帐号停止续期，到期后将无法使用VIP专属线路。',
  'record.memberPage.pageViewsOfActivationRecord': '会员页-开通记录页浏览量', //会员页-开通记录页浏览量',
  'record.refundFail.tryAgain':
    'Yêu cầu hoàn tiền không thành công vui lòng thử lại sau', //退款申请失败, 请稍候再试',
  'record.cancel': 'Hủy bỏ', //取消',
  'record.noRecordYet.goAndActive':
    'Chẳng có bản ghi nào Hãy nhanh chóng đăng ký thành viên~', //还没有记录哦, 快去开通会员吧~',
  'record.chooseRefundOrder': 'Chọn đơn hàng hoàn tiền', //选择退款订单',
  'record.weSupportRefund.oneUserHasOneService':
    'Chúng tôi hỗ trợ bạn yêu cầu hoàn tiền trong 7 ngày từ khi bạn mua thành viên siêu V Khoản tiền sẽ được trả lại theo đường gốc sau khi bạn chọn đơn hàng muốn hoàn tiền. Một người dùng chỉ được hưởng 1 lần dịch vụ hoàn tiền', //我们支持您在购买超级VIP会员后7天内申请退款, 请选择要退款订单, 退款后资金将原路返回。一个用户只可享受一次退款服务。',
  'record.failToGetList': 'Nhận danh sách không thành công', //获取列表失败',
  'record.failToGetActivationList':
    'Nhận danh sách bản ghi mở không thành công', //获取开通记录列表失败',
  'record.failToGetRefund': 'Nhận hoàn tiền không thành công', //获取退款情况失败',
  'record.getListFail': 'Nhận danh sách không thành công', //获取列表失败',
  'record.recordItem.numberOfRefund':
    '会员页-开通记录页, 订单卡片上的退款按钮点击量', //会员页-开通记录页, 订单卡片上的退款按钮点击量',
  'record.recordItem.getTime': 'Thời gian nhận được', //获得时间',
  'record.recordItem.refundTime': 'Thời gian hoàn tiền', //退款时间',
  'record.recordItem.refuseRefund': 'Không hoàn tiền được', //不可退款',
  'record.recordItem.refundComplete': 'Đã hoàn tiền xong', //退款已完成',
  'record.recordItem.addDay': 'Thời gian quyền lợi', //权益时长',
  'record.recordItem.handsel': 'Tặng', //赠送',
  'record.recordItem.orderNumber': 'Mã số đơn đặt', //订单编号',
  'record.recordItem.orderType': 'Phương thức thanh toán', //支付方式',
  'record.recordItem.orderNumberHasBeenCopied': 'Đã sao chép số đơn hàng', //订单编号已复制',
  'record.recordItem.refund': 'Trả lại tiền', //退款',
  'record.recordItem.outActivity': '外部活动',
  'record.resaonModal.refundReason': 'Lý do hoàn tiền', //退款原因',
  'record.resaonModal.supplement': 'Hướng dẫn bổ sung', //补充说明',
  'record.resaonModal.refundReasonPlaceholder':
    'Chọn điền Hãy cho chúng tôi biết lý do bạn cần hoàn tiền để chúng tôi thực hiện tối ưu hóa nhanh chóng Chỉ cần nhập tối đa 50 từ', //选填。告诉我们为什么您需要退款, 以便我们尽快进行优化, 最多可输入50个字。',
  'record.resaonModal.reasonLimitedText': 'Chỉ cần nhập tối đa 50 từ thôi nhé', //最多只能输入50个字哦',
  'record.resaonModal.failToSubmitRefundRequset':
    'Yêu cầu hoàn tiền không thành công', //退款申请提交失败了',
  'record.resaonModal.refundApplication': 'Yêu cầu hoàn tiền', //退款申请',
  'record.reasonModal.failToRefund': 'Thao tác sai', //操作失败',
  'record.reasonModal.successfulRefund': 'Thao tác thành công', //操作成功',
  'record.reasonModal.weSupportRefund.reviewDetailonITunes':
    'Chúng tôi hỗ trợ bạn đăng ký hoàn tiền trong vòng 7 ngày từ khi mua thành viên siêu V Vui lòng kiểm tra đơn hàng trên iTunes và đăng ký hoàn tiền', //我们支持您在购买超级VIP会员后7天内申请退款, 请在iTunes上查看该订单并进行退款申请。',
  'record.reasonModal.numberOfConfirmRefund':
    '会员页-开通记录页, 确定退款按钮点击量', //会员页-开通记录页, 确定退款按钮点击量',
  'record.reasonModal.ok': 'Vâng', //好的',
};

// 时间类型(通用)
const TimeType = {
  'timeType.day': 'Ngày', //天',
  'timeType.week': 'Tuần', //周',
  'timeType.month': 'Tháng', //月',
  'timeType.season': 'Quý', //季',
  'timeType.year': 'Năm', //年',
  'timeType.minute': 'Phút', //分钟',
  'timeType.hour': 'Tiếng', //小时',
  'timeType.sentence': '{day} ngày {hour} giờ {minute} phút {second} giây', // {day} 天 {hour} 时 {minute} 分 {second} 秒',
};

// 防锁定教程
const tutorial = {
  'tutorial.mi': 'Hướng dẫn điện thoại Xiaomi', //小米手机教程',
  'tutorial.vivo': 'Hướng dẫn điện thoại Vivo', //vivo手机教程',
  'tutorial.oppo': 'Hướng dẫn điện thoại OPPO', //OPPO手机教程',
  'tutorial.huawei': 'Hướng dẫn điện thoại Huawei', //华为手机教程',
  'tutorial.honor': 'Hướng dẫn điện thoại Honor', //荣耀手机教程',
  'tutorial.oneplus': 'Hướng dẫn điện thoại OnePlus', //一加手机教程',
  'tutorial.samsung': 'Hướng dẫn điện thoại Samsung', //三星手机教程',
  'tutorial.general': 'Hướng dẫn điện thoại khác', //其他手机教程',
  'tutorial.tips':
    'Vui lòng chọn thương hiệu điện thoại của bạn để xem Hướng dẫn tương ứng', //请选择您的手机品牌, 查看相对应教程',
  'tutorial.title': 'Hướng dẫn', //加速防掉线教程',
  'tutorial.purchase.title': 'Mua siêu VIP', //购买超级VIP',
  'tutorial.purchase.content0':
    'Nếu bạn không thể thanh toán khi mua thành viên qua Google Play, hãy thử xem các cách mua sau đây', //如您在使用Google Play支付购买会员时无法完成支付，可尝试使用以下途径购买：',
  'tutorial.purchase.title1': '1、Đến website chính thức để mua thành viên', //1、前往官网购买会员',
  'tutorial.purchase.content1':
    'Bạn có thể nhấn vào nút sau đây để đến website chính thức mua thành viên, hỗ trợ các loại cách thanh toán như Wechat/Alipay/Paypal,v.v.,  mua với giá ưu đãi hơn', //您可点击下方按钮前往官网购买会员，支持微信/支付宝/PayPal等支付方式，价格更优惠；',
  'tutorial.purchase.title2': '2、Cài đặt phiên bản mới nhất của QuickFox', //2、安装QuickFox最新版本',
  'tutorial.purchase.content2':
    'Bạn có thể tìm và tải phiên bản mới nhất của QuickFox từ website chính thức, hỗ trợ thanh toán WeChat/Alipay/Paypal', //可前往QuickFox官网下载最新版本，支持微信/支付宝/PayPal等支付方式；',
  'tutorial.purchase.title3': '3、Liên hệ với DVKH để mua', //3、联系客服购买',
  'tutorial.purchase.content3':
    'QuickFox hỗ trợ mua thành viên siêu VIP qua WeChat/Alipay/Ngân hàng bằng cách quét mã hoặc chuyển khoản, bạn có thể nhấn vào gốc trên trái APP tư vấn DVKH APP hoặc thêm QuickFox2020 tài khoản Wechat công khai để tư vấn ', //QuickFox支持通过微信/支付宝/银行以扫码或转账的形式购买超级VIP，您可以点击APP左上角咨询APP客服或添加官方微信号QuickFox2020进行咨询；',
  'tutorial.purchase.button': 'Mua tại website chính thức', //去官网页面购买',
  'tutorial.purchase.button1': 'Tải xuống phiên bản mới nhất', //下载最新版本',
};
// 邀请裂变1, 2期活动结束
const pointEventEnd = {
  'pointEventEnd.tooltipText': 'Xin lỗi, hoạt động đã kết thúc', //很遗憾, 活动已结束',
  'pointEventEnd.invitationWithPrize': 'Mời phần thưởng', //邀请有奖',
  'pointEventEnd.scanQRToFollow':
    'Quét mã QR để theo dõi chúng tôi và hẹn lần sau~', //扫码关注公众号 下次再约哦~',
  'pointEventEnd.scanQRToFollowForMoreInfo':
    'Nắm bắt thông tin sự kiện thú vị hơn trong tầm tay của bạn', //扫码关注QuickFox公众号, 更多精彩活动资讯一手掌握',
  'pointEventEnd.copyOfficialAccount': 'Sao chép tài khoản chính thức', //复制公众号',
};
// 邀请裂变第3期
const taskCenterV3 = {
  'task.mission.pv': '任务中心页浏览量', //任务中心页浏览量',
  'task.lookforwardForMoreTasks': 'Hãy chờ thêm nhiều nhiệm vụ', //更多任务敬请期待',
  'task.taskCard.isPcAppNotice':
    'Nhiệm vụ mời bạn bè chỉ được hoàn thành trên điện thoại di động Vui lòng quét mã tải QuickFox APP để thực hiện nhiệm vụ', //邀请任务暂时仅支持在移动端完成, 请扫码下载QuickFox APP进行任务。',
  'task.taskCard.numberOfInvitationClick': '邀请任务点击量', //邀请任务点击量',
  'task.taskCard.numberOfFollowTaskClick': '关注公众号任务点击量', //关注公众号任务点击量',
  'task.taskCard.numberOfCheckInClick': '福利页, 签到任务点击数', //福利页, 签到任务点击数',
  'task.detail.follow.numberOfFollowPage': '关注公众号任务页浏览量', //关注公众号任务页浏览量',
  'task.invite.invitePagePv': '邀请任务页浏览量', //邀请任务页浏览量',
  'task.invite.cpoyPv': '复制邀请链接点击量', //复制邀请链接点击量',
  'task.invite.loadingText': 'Còn chưa có bản ghi nào Hãy mời bạn bè ngay~', //还没有记录哦,快去邀请朋友吧~',
  'task.invite.nodataText': 'Chưa có bản ghi Làm nhiệm vụ để nhận xu vàng nhé~', //还没有记录哦, 快去做任务获得金币吧~',
  'task.invite.noContentText': 'Không có bản ghi', //暂无记录',
  'task.invite.noContentTextPart1': 'Hãy mời bạn bè ngay', //快去邀请朋友吧',

  'task.invite.coinDetail': 'Chi tiết tiền xu', //金币明细',
  'task.invite.coinDetailPv': '金币明细浏览量', //金币明细浏览量',

  'task.invite.numberOfClickInviteBtn': '邀请按钮点击量', //邀请按钮点击量',
  'task.invite.posterAndFace2Face': 'Mời qua áp phích & mời trực tiếp', //海报&面对面邀请',
  'task.invite.inviteForNow': 'Mời ngay', //立即邀请',
  'task.invite.linkCopied': 'Đã sao chép liên kết', //链接已复制'
  'task.invite.posterLoading': 'Đang tải áp phích...', //海报正在加载...',
  'task.invite.descript.title.part1': 'Mỗi mời một bạn bè thể nhận được tối đa', //每邀请一位好友, 最多可以获得',
  'task.invite.descript.title.part2': 'Mời ngay', //快去邀请吧',

  'task.invite.reward.descrip': 'Hướng dẫn phần thưởng', //奖励说明',
  'task.invite.reward.descrip.part1':
    '1. Bạn và bạn bè của bạn sẽ nhận được VIP trong 1 ngày sau khi người bạn được mời tăng tốc trên thiết bị di động.',

  'task.invite.reward.descrip.part2':
    '2. Sau khi người bạn được mời hoàn tất việc mua hàng, bạn có thể nhận được 10% thời gian mua hàng của người bạn đó làm phần thưởng cho lời mời.',
  'task.invite.reward.descrip.part3':
    '3. Đăng ký hàng loạt bằng các phương tiện không phù hợp sẽ bị coi là đăng ký bất hợp pháp và bạn sẽ không thể nhận được phần thưởng lời mời.',
  'task.invite.reward.descrip.part4':
    '4. Nếu một người bạn hoàn lại tiền, phần thưởng lời mời tương ứng mà bạn nhận được sẽ bị hủy đồng thời.',
  'task.invite.reward.descrip.part5':
    '2. Sau khi người bạn được mời hoàn tất việc mua hàng, bạn có thể nhận được 10% + 5% thời gian mua hàng của người bạn đó làm phần thưởng cho lời mời.',
    

  'task.invite.details.tab.waitingData': 'Đợi tăng tốc', //待加速',
  'task.invite.details.tab.waitingToGiveData': 'Chờ phát hành', //待发放',
  'task.invite.details.tab.completedData': 'Đã hoàn thành', //已完成',
  'task.invite.details.tab.inValidData': 'Đã hết hiệu lực', //已失效',
  'task.invite.details.tab.closed': 'Đóng', //已关闭',
  'task.invite.details.tab.toAppeal': 'Chờ khiếu nại', //待申诉',
  'task.invite.details.tab.toPaid': 'Chờ thanh toán', //待支付',
  'task.invite.details.tab.violationData': 'Người dùng vi phạm', //违规用户',
  'task.invite.details.content.type1':
    'Bạn của bạn đã hoàn tất đăng ký. Hãy để bạn của bạn tải QuickFox trên thiết bị mới và tăng tốc một lần. Phần thưởng sẽ được ghi có trong vòng 1 giờ.', //好友已注册, 让好友在新设备上下载APP并使用该注册手机号登录进行一次加速, 奖励立刻到账！',
  'task.invite.details.content.type2':
    'Bạn đã kiếm được VIP trong 1 ngày bằng cách mời thành công một người bạn', //已成功邀请好友, 1天VIP时长奖励到帐咯',
  'task.invite.details.content.type3':
    'Ồ, người bạn này không thực hiện lần tăng tốc đầu tiên trên thiết bị mới nên lời mời này đã thất bại~', //哎呀，该好友没有在新设备进行首次加速，本次邀请失败了~',
  'task.invite.details.content.type4':
    'Bạn đã mời thành công một người bạn và đang được xem xét rủi ro hệ thống. VIP 1 ngày dành cho bạn và bạn bè của bạn sẽ được ghi có trong vòng 1 giờ', //已成功邀请好友, 系统风险审核中，您和好友的1天VIP时长奖励将在1小时内到帐',
  'task.invite.details.content.type5':
    'Bạn bè này vi phạm quy định không thể nhận được phần thưởng', //该好友存在违规操作, 无法发放奖励',
  'task.invite.details.loading': 'Đang tải chi tiết mời...', //邀请明细正在加载...',
  'task.invite.details.load.faild': 'Lỗi tải chi tiết mời', //邀请明细加载失败',

  'task.signin.viewPv': '福利-签到页, 页面浏览量', //福利-签到页, 页面浏览量',
  'task.signin.walfare': 'Phúc lợi đăng ký người dùng mới', //新用户签到福利',
  'task.signin.walfare.detail':
    'Sau khi đăng ký theo lời mời của bạn bè trong 7 ngày đầu tiên Đăng ký liên tục 3 ngày sẽ nhận được phần thưởng 100 xu vàng. Bạn cũng có thể tham gia hoạt động mời bạn bè cùng đăng ký để nhận được phần thưởng xu vàng tương đương', //接受好友邀请注册后前7天, 连续签到3天, 将可以获得额外100金币奖励。您也可以参与邀请活动, 邀请朋友一起签到, 可获得同等签到金币奖励哟。',
  'task.signin.walfare.downloadForSignin':
    'Quét mã tải xuống QuickFox APP mời bạn bè đăng ký', //扫码下载QuickFox APP邀请好友签到',
  'task.signin.walfare.goToInviteWithPhone': 'Mời ngay trên điện thoại di động', //去手机端邀请',
  'task.signin.walfare.goToInvite': 'Đi mời>', //去邀请>',
  'task.signin.walfare.numberOfCheckInClick': '福利-签到任务页, 签到点击数', //福利-签到任务页, 签到点击数',
  'task.signin.walfare.signing':
    'Đang tải dữ liệu vui lòng đợi trong giây lát...', //签到数据加载中, 请稍后...',
  'task.signin.walfare.signSuccessTipsPart1':
    'Đăng ký thành công đã nhận được {todayReward} xu vàng', //签到成功, {todayReward}金币到手, ',
  'task.signin.walfare.signSuccessTipsPart2':
    'Tặng bạn 100 xu vàng do bạn nhận được phúc lợi đăng ký liên tục cho người dùng mới', //因您获得新用户连续签到福利, 再送您100金币~',
  'task.signin.walfare.signSuccessTipsPart3': 'Hãy nhớ ghé thăm mỗi ngày nha~', //记得每天都来哦~',

  'task.goods.detail.title': 'Chi tiết sản phẩm', //商品详情',
  'task.goods.detail.lessCoin': 'Không đủ đồng xu vàng', //金币不足',
  'task.goods.detail.lessCoinTips':
    'Xu vàng của bạn không đủ Làm nhiệm vụ để nhận xu vàng nhé~', //你的金币不足哦, 快去做任务赚取金币吧~',
  'task.goods.detail.lessCoinTips.goForMoreCoin':
    'Xu vàng của bạn không đủ Làm nhiệm vụ để nhận xu vàng nhé~<br>Thiết bị iOS không hỗ bù giá lệch Vui lòng thực hiện giao dịch chuyển đổi trên thiết bị hệ thống khác', //你的金币不足哦, 快去做任务赚取金币吧~<br>ios设备暂不支持补差价兑换, 请在其他系统设备上进行兑换操作',

  'task.goods.detail.exchangeRes.goCheck':
    'Đã chuyển đổi thành công, vào trang "Lịch sử chuyển đổi" để xem và nhận sản phẩm', //已兑换成功，可前往“兑换记录”页面查看领取商品',

  'task.goods.detail.exchange': 'Đổi hàng', //兑换商品',
  'task.goods.detail.exchangeSuccessful': 'Đổi thành công', //兑换成功',
  'task.goods.detail.exchangeSuccessful.congratulate':
    'Chúc mừng bạn đã hoàn thành chuyển đổi Có thể nhận sản phẩm bằng các phương thức sau đây', //恭喜, 你已兑换成功, 可通过下列途径领取商品~',
  'task.goods.detail.exchangeSuccessful.tips':
    'Chúc mừng bạn đã đăng ký thành công chuyển đổi Chúng tôi sẽ hoàn thành việc xét duyệt trong 2 ngày làm việc Bạn có thể kiểm tra tiến độ trên trang "Lịch sử chuyển đổi" ', //恭喜, 你已申请兑换成功, 我们将在2个工作日内完成审核, 可在“兑换记录”页面查看进度哦~',
  'task.goods.detail.exchangeSuccessful.getVip':
    'Chuyển đổi thành công Đặc quyền siêu V đã được nạp vào tài khoản của bạn', //兑换成功, 超级VIP特权已充值到您账户',
  'task.goods.detail.exchange.fail.retry':
    'Lỗi chuyển đổi Vui lòng thử lại sau', //兑换失败, 请稍后再试',
  'task.goods.detail.exchangeConfirm': 'Xác nhận đổi hàng này không?', //确定兑换该商品吗？',
  'task.goods.detail.stock.less': 'Tồn kho không đủ', //库存不足',
  'task.goods.detail.stock.less.tips':
    'Sản phẩm này hiện đã hết hàng Không đổi được~', //该商品库存不足, 暂时无法兑换哦~',
  'task.goods.detail.signinDay.less': 'Không đủ số ngày đăng ký', //签到天数不足',
  'task.goods.detail.signinDay.less.tips':
    'Sản phẩm này cần phải đăng ký {day} lần trở lên mới có thể đổi Hãy đi đăng ký ngay~', //该商品需要签到{day}次及以上才可以兑换哦, 快去签到吧~',
  'task.goods.detail.remain': 'Còn lại', //剩余',
  'task.goods.detail.tillNext': 'Cách tranh mua lần sau vẫn còn', //距下次抢购还有',
  'task.goods.blockTitle': 'Giới thiệu sản phẩm', //商品介绍',
  'task.goods.noStock': 'Tạm không có tồn kho', //暂无库存',
  'task.goods.noConvertible': 'Không đổi được', //不可兑换',
  'task.goods.overConvertibleLimit': 'Đã đến giới hạn đổi', //已达兑换上限',
  'task.goods.exchange.now': 'Chuyển đổi ngay', //立即兑换',
  'task.goods.order.total': 'Tổng số tiền đơn đặt', //订单总额',
  'task.goods.coinForPay': 'Thanh toán bằng xu vàng', //支付金币',
  'task.goods.require.diff': 'Cần bù giá lệch', //需补差价',
  'task.goods.pay.diff': 'Hoàn lại giá lệch', //补差价',
  'task.goods.pay.diff.exchange': 'Đổi giá lệch thanh toán', //支付差价兑换',
  'task.goods.pay.way': 'Vui lòng chọn phương thức thanh toán', //选择支付方式',
  'task.goods.contact.offcialAccount': 'Liên hệ với DVKH Wechat QuickFox', //联系QuickFox微信客服{number}',
  'task.goods.offcialAccount.copy': '(Nhấn và sao chép tài khoản Wechat)', //(点击复制微信号)',
  'task.goods.contact.customerService': 'Liên hệ với DVKH Wechat QuickFox', //联系QuickFox微信客服',
  'task.goods.go': 'Đi ngay', //'立即前往',

  'task.point.detail.coinType0': 'Bốc thăm xu vàng', //金币抽奖',
  'task.point.detail.coinType1': 'Đổi xu vàng', //金币兑换',
  'task.point.detail.coinType2': 'Mời bạn bè {account} đăng ký', //邀请好友 {account} 注册',
  'task.point.detail.coinType3': 'Đăng ký qua Sự mời của bạn bè', //通过好友邀请注册',
  'task.point.detail.coinType4':
    'Người dùng xếp hạng cao được thưởng từ nền tảng', //用户排名靠前平台奖励',
  'task.point.detail.coinType5': 'Đánh dấu hàng ngày', //每日签到',
  'task.point.detail.coinType6': 'Theo dõi tài khoản chính thức Wechat', //关注微信公众号',
  'task.point.detail.coinType7': 'Đơn hàng đổi của cửa hàng hết hạn', //商城兑换订单失效',
  'task.point.detail.coinType8': 'Lợi nhuận từ bốc thăm xu vàng', //金币抽奖收益',
  'task.point.detail.coinType9':
    'Phần thưởng thêm cho việc đăng ký liên tục trong 3 ngày', //连续签到3天额外奖励',
  'task.point.detail.coinType10':
    'Phần thưởng thêm cho việc bạn bè đăng ký liên tục trong 3 ngày', //好友连续签到3天额外奖励',
  'task.point.detail.bindReward': 'Phần thưởng khi liên kết', //'绑定赠送',
  'task.point.detail.shareReward': 'Phần thưởng cho nhiệm vụ chia sẻ', //'分享任务奖励',
  'task.point.detail.coinType11': 'Tặng xu vàng Trung thu', //中秋活动金币赠送',
  'task.point.detail.coinType13': 'Xu vàng hết hạn', //金币到期',
  'task.point.detail.coinType16': 'Dự đoán World Cup', //世界杯竞猜活动',

  'task.redeem.modal.title': 'Tạm thời không hỗ trợ', //暂不支持',
  'task.redeem.modal.content':
    'Thiết bị iOS không hỗ trợ đổi giá lệch Vui lòng đổi trên thiết bị hệ thống khác', //ios设备暂不支持补差价兑换, 请在其他系统设备上进行兑换操作',
  'task.redeem.exchangeRecordPv': '兑换记录浏览量', //兑换记录浏览量',
  'task.redeem.exchangeRecord': 'Nhật ký chuyển đổi', //兑换记录',
  'task.redeem.exchangeCode': 'Mã chuyển đổi: ', //兑换码：',
  'task.redeem.draw.link': 'Liên kết nhận: ', //领取链接：',
  'task.redeem.draw.code': 'Nhận mã sản phẩm: ', //领取商品码：,
  'task.redeem.exchangeCode.use': 'Cách đổi', //兑换方式,
  'task.redeem.norecord.goForIt': 'Không có bản ghi Đến cửa hàng đổi ngay~', //还没有记录哦, 快去兑换商品吧~',
  'task.redeem.record.tips':
    'QuickFox sẽ hoàn thành việc gửi trong 2 ngày làm việc (Thứ Hai đến Thứ Sáu là ngày làm việc) Sau khi gửi xong hãy kiểm tra và sử dụng ngay', //QuickFox将在2个工作日（周一至周五为工作日）内完成发放, 发放后请尽快查看使用, ',
  'task.redeem.clickForIt': 'Nhấn và kiểm tra', //点此查看',
  'task.redeem.copy': 'Sao chép', //复制',
  'task.redeem.copied': 'Đã sao chép', //已复制',
  'task.redeem.payForNow': 'Thanh toán ngay', //立即支付',
  'task.redeem.orderWill':
    'Đơn hàng sẽ mất hiệu lực trong {time} Vui lòng thanh toán ngay', //订单将于 {time} 失效, 请及时支付',
  'task.redeem.dataReady': 'Đang chuẩn bị dữ liệu Vui lòng chờ...', //数据准备中, 请稍候...',
  'task.redeem.drawed': 'Trúng giải', //抽中',
  'task.redeem.receive': 'Nhận ngay', //立即领取',
  'task.redeem.drawed.limitDate': 'Thời hạn phần thưởng đến', //奖品有效期至',
  'task.redeem.drawed.waitForit':
    'Trúng giải {lotteryName} Đợi cấp cho (cấp trong 2 ngày làm việc)', //抽中 {lotteryName} , 等待发放（2个工作日内完成发放）',
  'task.redeem.drawed.waitForit.physical':
    'Trúng giải {lotteryName} Vui lòng liên hệ với DVKH Wechat chenhan_xy để nhận', //抽中 {lotteryName} , 请联系微信客服chenhan_xy领取',
  'task.redeem.drawed.violation':
    'Trúng giải {lotteryName} Vì vi phạm quy định nhận xu vàng thì đơn hàng này đã bị đóng', //抽中 {lotteryName} , 因违规获得金币, 该订单已被关闭',
  'task.redeem.drawed.success':
    'Trúng giải {lotteryName} Thành viên đã được nạp vào tài khoản của bạn Vui lòng kiểm tra', //抽中 {lotteryName} , 会员已充值到您账户, 请注意查收',
  'task.redeem.drawed.goods.success':
    'Trúng giải {lotteryName} Đã được nạp vào tài khoản của bạn Vui lòng kiểm tra', //抽中 {lotteryName} , 已充值到您账户, 请注意查收',
  'task.redeem.drawed.goods.success.physical':
    'Trúng giải {lotteryName} Phần thưởng đã được cấp', //抽中 {lotteryName} , 奖品已完成发放。',
  'task.redeem.drawed.svip.success':
    'Thành viên siêu VIP đã được nạp vào tài khoản của bạn Vui lòng kiểm tra', //超级VIP会员已充值到您账户, 请注意查收',
    'task.redeem.drawed.vip.success':
    'Thành viên VIP đã được nạp vào tài khoản của bạn Vui lòng kiểm tra', //VIP会员已充值到您账户, 请注意查收',
  'task.redeem.drawed.violation.closed':
    'Vì vi phạm quy định nhận xu vàng thì đơn hàng này đã bị đóng', //因违规获得金币, 该订单已被关闭',
  'task.redeem.violation.contact':
    'Không thể cấp bốc thăm trúng thưởng do nhận xu vàng vi phạm quy định Nếu có thắc mắc Vui lòng gửi văn bản kháng cáo vào email: info@quickfox.com.cn', //因违规获得金币, 不予发送抽奖奖励。如有疑问请发申诉材料到邮箱：info@quickfox.com.cn。',
  'task.redeem.limit.contact':
    'Vì tài khoản được mời hoạt động bất thường Việc đổi bị hạn chế. Vui lòng gửi văn bản kháng cáo vào email: info@quickfox.com.cn. Văn bản kháng cáo bao gồm tài khoản QuickFox Lịch sử trò chuyện với tất cả bạn bè đã mời gần đây', //因被邀请账号存在异常操作, 暂且限制兑换。请发申诉材料到邮箱：info@quickfox.com.cn。申诉材料包括QuickFox账号, 近期邀请所有好友的聊天记录进行申诉。',
  'task.redeem.order.fail':
    'Đơn hàng này đã hết hạn Xu vàng đã được hoàn trả vào tài khoản', //该笔订单已失效, 所消耗金币已退回账户',

  'task.redeem.draw.customer.service':
    'Vui lòng đọc mô tả chi tiết của sản phẩm đổi kịp thời, thêm DVKH Wechat để hoàn tất việc đổi', //请及时查看兑换商品的详情描述，添加客服微信完成兑换。',
  'task.redeem.drawLink.tips':
    'Liên kết nhận: đợi cấp cho (cấp trong 2 ngày làm việc)', //领取链接：等待发放（2个工作日内完成发放）',
  'task.redeem.drawLink.limit.tips':
    'Mã sản phẩm sẽ hết hạn trong {expireTime} Vui lòng đổi ngay', //商品码将在{expireTime}过期, 请尽快兑换',
  'task.redeem.noname': 'Không có tên', //暂无名称',
  'task.redeem.checkMethod': 'Xem phương thức chuyển đổi', //'查看兑换方式',

  'task.event.rule': 'Quy tắc', //活动规则',
  'task.event.question': 'Những vấn đề thường gặp', //常见问题',
  'task.event.needToKnow': 'Luật mời', //活动须知',

  'task.goods.loadGoods.fail': 'Tải danh sách sản phẩm không thành công', //商品列表加载失败',
  'task.goods.coin.loadFailed': 'Tải số lượng xu vàng không thành công', //金币数加载失败',
  'task.goods.shop.requreUpdate':
    'Truy cập cửa hàng xu vàng bất thường Để đảm bảo trải nghiệm người dùng tốt hơn Vui lòng bạn nâng cấp đến ứng dụng khách hàng mới nhất', //金币商城访问异常, 为保证更好的用户体验, 请您升级到最新客户端',
  'task.goods.coin.less.loadFailed': 'Lỗi tải số lượng xu vàng còn lại', //剩余金币数加载出错了',
  'task.goods.coin.balance': 'Số dư xu vàng', //金币余额',
  'task.goods.coin.detail.view': 'Xem bản ghi thu chi xu vàng', //查看金币收支记录',
  'task.goods.coin.exchange.record': 'Xem đơn hàng đổi của cửa hàng', //查看商城兑换订单',

  'task.lottery.detailPv': '抽奖商品详情页浏览量', //抽奖商品详情页浏览量',
  'task.lottery.waitMsg': 'Đang bốc thăm hãy đợi chút để quay thưởng~', //正在抽奖, 再等等, 马上开奖了~',
  'task.lottery.drawOut':
    'Phần thưởng đã hết Đang được bổ sung Vui lòng quay lại sau nhé~', //奖品被抽光啦, 正在补充奖品中, 稍后再来哦~',
  'task.lottery.networkErrMsg':
    'Lỗi kết nối mạng Vui lòng kiểm tra mạng và thử lại sau~', //当前网络异常, 请检查网络后重试~',
  'task.lottery.Replenishment':
    'Phần thưởng đã hết Đang được bổ sung Vui lòng quay lại sau nhé~', //奖品被抽光啦, 正在补充奖品中, 稍后再来哦~',
  'task.lottery.balance.lessMsg':
    'Số dư đồng xu vàng không đủ Hãy nhanh chóng kiếm đồng xu vàng nhé~', //金币余额不足, 快去赚金币吧~',
  'task.lottery.forAWhile':
    'Hiện tại không thể tham gia bốc thăm Hãy quay lại sau nhé', //当前无法进行抽奖, 晚点再来看看吧',
  'task.lottery.luckyId.error': 'Lỗi id trúng giải: ', //中奖id错误：',
  'task.lottery.luckyId.cannotFind':
    'chưa phát hiện phần thưởng id trúng giải là: ', //没找到奖品, 中奖id为：',
  'task.lottery.interrupt':
    'Ôi quá trình bốc thăm đã bị gián đoạn bất ngờ! Vui lòng thử lại sau hoặc liên hệ DVKH', //哎呀, 抽奖意外中断了！请稍后重试或联系客服',
  'task.lottery.start.first': 'Hãy bắt đầu trước', //请先开始',
  'task.lottery.number.error': 'Lỗi con số', //数字错误',
  'task.lottery.now': 'Ngay', //立即',
  'task.lottery': 'Bốc thăm', //抽奖',
  'task.lottery.add': 'Đang bổ sung', //补充',
  'task.lottery.rewards': 'phần thưởng', //奖品中',
  'task.lottery.draw.congratulate': 'Chúc mừng! Bạn trúng thưởng rồi', //恭喜！您抽中了',
  'task.lottery.coin.once': 'xu vàng/lần', //金币/次',
  'task.lottery.draw.view':
    'Đợi hệ thống cấp sau thì có thể nhận Có thể kiểm tra tiến độ tại trang "Phúc lợi-Lịch sử chuyển đổi"', //, 等待系统发放后即可领取, 可在“福利-兑换记录“页查看进度',
  'task.lottery.draw.viewReward':
    'Phần thưởng đã được gửi vào tài khoản của bạn Có thể kiểm tra tại trang "Phúc lợi-Lịch sử chuyển đổi"', // , 奖品已发送到您账户, 可在“福利-兑换记录”页查看',
  'task.lottery.draw.physicalGoods':
    'Liên hệ với DVKH để nhận  Có thể kiểm tra cách liên lạc và tiến độ tại trang "QuickFox APP-Phúc lợi-Lịch sử chuyển đổi"', // , 请及时联系客服领取, 可在“QuickFox App-福利-兑换记录”页查看联系方式及进度~',
  'task.lottery.rule': 'Luật bốc thăm', //抽奖规则',
  'task.lottery.rulePv': '抽奖规则点击量', //抽奖规则点击量',
  'task.lottery.myRewardPv': '我的奖品点击量', //我的奖品点击量',
  'task.lottery.tab.item1.part1': 'Nội Quy', //活动',
  'task.lottery.tab.item1.part2': 'Hoạt Động', //规则',
  'task.lottery.tab.item2.part1': 'Giải thưởng', //我的',
  'task.lottery.tab.item2.part2': 'của Tôi', //奖品',
  'task.lottery.coin.earnIt': 'Kiếm xu vàng>>', //赚金币>>',
  'task.lottery.more': 'Nhiều bốc thăm hơn', //更多抽奖',
  'task.lottery.result': 'Kết quả bốc thăm', //抽奖结果',
  'task.lottery.again': 'Rút lại', //再抽一次',
  'task.lottery.toView': 'Đi xem', //前往查看',
  'task.lottery.lickDrawNowPv': '“立即抽奖”按钮成功点击量', //“立即抽奖”按钮成功点击量',
  'task.lottery.error': 'Bốc thăm gặp lỗi', //抽奖报错了：',
  'task.lottery.notLogin': 'Chưa đăng nhập', //未登录',

  'task.walfare.center': 'Trung tâm phúc lợi', //福利中心',
  'task.walfare.info.refresh.done': 'Đã làm mới thông tin cá nhân', //已刷新个人信息',
  'task.walfare.info.refresh.error': 'Lỗi làm mới thông tin cá nhân', //刷新个人信息出错了',
  'task.walfare.refreshed': 'Đã làm mới', //已刷新',
  'task.walfare.refresh.error': 'Lỗi làm mới', //刷新出错了',
  'task.walfare.viewPv': '福利页浏览量', //福利页浏览量',
  'task.walfare.all': 'Tất cả', //全部',
  'task.walfare.giftMoney': 'Hồng bao', //红包',
  'task.walfare.coin.clickPv': '金币明细点击量', //金币明细点击量',
  'task.walfare.coin.left': 'Xu vàng còn lại', //剩余金币',
  'task.walfare.coin.mine': 'Xu vàng của tôi', //我的金币',
  'task.walfare.coin.expire.thisMonth': 'Xu vàng hết hạn của tháng này', //本月到期金币',
  'task.walfare.coin.expire.time': 'Thời gian hết hạn', //到期时间',
  'task.walfare.coin.earn': 'Kiếm xu vàng', //赚取金币',
  'task.walfare.exchange.record': 'Nhật ký chuyển đổi', //兑换记录',
  'task.walfare.exchange.recordPv': '兑换记录点击量', //兑换记录点击量',
  'task.walfare.coin.descrip': 'Hướng dẫn xu vàng', //金币说明',
  'task.walfare.coin.descrip.usage': 'Xu vàng có thể dùng để làm gì? ', //金币有什么用？',
  'task.walfare.coin.descrip.usage.part1':
    'Xu vàng là tài sản tiền tệ ảo của bạn trong QuickFox Bạn có thể sử dụng xu vàng để đổi các phần quà như hồng bao tiền mặt, thành viên siêu VIP... ', //金币是您在QuickFox的虚拟货币资产, 您可使用金币在福利中心兑换现金红包、超级VIP会员等各种商品。',
  'task.walfare.coin.descrip.channel': 'Cách để nhận xu vàng? ', //如何获得金币？',
  'task.walfare.coin.descrip.channel.part1':
    'Có thể nhận được xu vàng bằng cách hoàn thành nhiệm vụ như đăng ký hằng ngày, mời bạn bè, theo dõi tài khoản chính thức QuickFox...', //可通过完成每日签到、邀请好友、关注QuickFox公众号等任务获得金币。',
  'task.walfare.coin.descrip.expire': 'Hướng dẫn xu vàng hết hạn: ', //金币到期说明：',
  'task.walfare.coin.descrip.expire.part1':
    'Xu vàng được tạo ra của mỗi tháng sẽ hết hạn sau 3 tháng, xu vàng sẽ hết hạn trong tháng này sẽ được đưa vào danh sách xu vàng sắp hết hạn, ưu tiên sử dụng xu vàng sắp hết hạn khi đổi. Nếu không đổi xu vàng kịp thời, xu vàng sẽ hết hạn và không thể khôi phục.', //每月的产生的金币将在3个月后到期，本月将到期的金币将进入到期金币列表中，兑换时优先消耗即将到期金币。若未及时兑换，金币将过期失效，无法找回。',
  'task.walfare.coin.noticePv': '公告点击量', //公告点击量',
  'task.walfare.detail.view': 'Xem chi tiết', //查看详情',
  'task.walfare.tasks.more': 'Nhiều nhiệm vụ hơn', //更多任务',
  'task.walfare.tasks.clickPv': '更多任务点击量', //更多任务点击量',

  'task.goods.clickPv': '商品点击量', //商品点击量',

  // 邀请活动2期
  'welfare.invitev2.page.title': 'Tặng thành viên cho', //赠送会员给好友',
  'welfare.invitev2.page.getFail': 'Lỗi nhận dữ liệu', //获取数据失败',
  'welfare.invitev2.page.directions':
    'Lời khuyên: sau khi kích hoạt cơ hội miễn phí, đơn hàng thanh toán mới nhất của bạn sẽ mất tư cách hoàn trả trong 7 ngày không lý do', //友情提示：激活赠送名额后，您最新的一笔付费订单将失去7天无理由退款资格。',
  'welfare.invitev2.rule.title': 'Quy tắc tặng', //赠送规则',
  // 'welfare.invitev2.rule.content': `
  //   1、即日起，会员剩余时长≥30天的用户可激活并领取3个会员赠送名额（需成功购买过会员，且只可领取一次），并在规定时间内通过链接形式分享给好友，好友完成注册后即可领取30天QuickFox会员；<br/>
  //   2、激活赠送名额后，您最新一笔付费订单将撤销7天无理由退款资格，请在确认已同意该规则后再激活名额；<br/>
  //   3、同一手机号码只能领取一次 15 天会员，无法再次领取；<br/>
  //   4、用户参加活动过程中，如用户存在以所获得的优惠权益进行盈利或非法获利、以任何形式转让或转移其所享有的优惠权益、非本人使用的，或者QuickFox有合理理由怀疑用户存在不当使用权益的，QuickFox将取消用户的权益资格，并有权撤销相关违规交易、收回优惠权益；<br/>
  //   5、如有任何关于本活动的资讯和帮助，请咨询QuickFox在线客服，本活动与苹果公司（Apple Inc.）无关；<br/>
  //   6、在法律范围内，QuickFox对本次活动保留最终解释权；<br/>`,
  'welfare.invitev2.rule.content': `
    1. Người dùng có đặc quyền VIP có thể kích hoạt và nhận 3 hạn ngạch quà tặng thành viên mỗi tháng (phải mua thành viên thành công và thời gian thành viên còn lại lớn hơn hoặc bằng 30 ngày). Chia sẻ liên kết với bạn bè trong thời gian quy định và bạn bè có thể nhận được tư cách thành viên QuickFox của thời gian tương ứng sau khi hoàn tất đăng ký; <br/>
    2. Mỗi lần bạn kích hoạt hạn ngạch quà tặng, đơn hàng đã thanh toán gần nhất của bạn sẽ bị thu hồi để hoàn lại tiền không có lý do trong 7 ngày. Vui lòng xác nhận rằng bạn đã đồng ý với quy định này trước khi kích hoạt hạn ngạch; <br/>
    3. Cùng một số điện thoại chỉ được nhận VIP 15 ngày một lần và không được nhận lại lần nữa; <br/>
    4. Trong quá trình tham gia sự kiện, nếu người dùng đã kiếm được lợi nhuận hoặc lợi nhuận bất hợp pháp bằng các quyền và lợi ích ưu đãi đã đạt được, chuyển nhượng các quyền và lợi ích ưu đãi dưới bất kỳ hình thức nào hoặc sử dụng chúng cho mục đích phi cá nhân hoặc QuickFox có lý do chính đáng để nghi ngờ rằng người dùng đã sử dụng các quyền và lợi ích không đúng cách, QuickFox sẽ hủy tư cách quyền và lợi ích của người dùng và có quyền hủy các giao dịch bất hợp pháp có liên quan và thu hồi các quyền và lợi ích ưu đãi; <br/>
    5. Nếu bạn có bất kỳ thông tin và trợ giúp nào về vấn đề này sự kiện, vui lòng tham khảo dịch vụ khách hàng trực tuyến của QuickFox. Sự kiện này không liên quan đến Apple Inc. (Apple Inc.); <br/>
    6. Trong phạm vi pháp lý, QuickFox giữ quyền giải thích cuối cùng cho sự kiện này; <br/>`,
  'welfare.invitev2.link.title':
    'Thẻ thành viên QuickFox miễn phí dành cho bạn', //赠送你一张QuickFox会员卡',
  'welfare.invitev2.link.descrip':
    'Ứng dụng tăng tốc về nước QuickFox, thương hiệu lựa chọn hàng đầu cho bạn trở về nước, tăng tốc nhanh hơn', //QuickFox回国加速器，华人回国加速首选品牌，加速快人一步',

  'welfare.invitev2.card.type': 'QuickFox VIP', //QuickFox会员',
  'welfare.invitev2.card.nocheck': 'Không có người nhận', //无人领取',
  'welfare.invitev2.card.sendTo':
    'Tặng cho một người bạn kết thúc bằng {account}', //已成功赠送给尾号{account}好友',

  'welfare.invitev2.msgs.title': 'Để lại lời nhắn cho bạn bè', //给好友留言',
  'welfare.invitev2.msgs.next': 'Thay đổi', //换一条',

  'welfare.invitev2.btn.allCheck': 'Bạn bè đã nhận hết card thành viên', //会员卡已全部被好友领取',
  'welfare.invitev2.btn.giftNow': 'Tặng ngay', //立即赠送',
  'welfare.invitev2.btn.timeEnd': 'Thời gian miễn phí đã kết thúc', //赠送时间已结束',
  'welfare.invitev2.btn.submitAgain': 'Đăng ký tặng tiếp', //申请继续赠送',
  'welfare.invitev2.btn.upgradeToVip': 'Nâng cấp lên VIP', //升级为VIP',
  'welfare.invitev2.btn.leftTime': 'Thời gian miễn phí còn lại', //剩余赠送时间',
  'welfare.invitev2.btn.copyLink': 'Sao chép liên kết chia sẻ', //复制分享链接',
  'welfare.invitev2.btn.copied': 'Đã sao chép liên kết miễn phí', //赠送链接已复制',
  'welfare.invitev2.modal.cantGift': 'Hiện tại không thể tặng cho thành viên', //当前无法赠送会员',
  'welfare.invitev2.modal.content':
    'Sau khi mua thành công một lần thành viên có thể tặng thành viên cho bạn bè, sau khi bạn bè đăng ký sẽ nhận được thành viên QuickFox đó', //成功购买一次会员后可赠送会员给好友，好友注册后就可获得QuickFox会员哦~',
  'welfare.invitev2.modal.getIt': 'Đã hiểu', //知道了',
  'welfare.invitev2.modal.buyVip': 'Mua ngay thành viên', //立即购买会员',
  'task.detail.email.title': 'Ràng buộc', //完善账号信息任务',
  'task.detail.email.rule1': 'Quy tắc', //活动规则',
  'task.detail.email.rule2':
    'Người dùng có thể nhận phần thưởng bằng cách liên kết email hoặc số điện thoại', //用户可通过绑定邮箱或手机号领取奖励',
  'task.detail.email.rule3':
    'Chỉ được nhận 1 lần phần thưởng hoạt động này, không thể nhận lặp lại', //本次活动奖励只可领取一次，无法重复领取',
  'task.detail.email.rule4':
    'Những người dùng đã ghép nối với email, số điện thoại có thể nhận trực tiếp phần thưởng hoạt động qua trang này. Người dùng đăng ký qua email, số điện thoại sẽ tự động nhận phần thưởng', //已绑定邮箱、手机号的用户可通过本页面直接领取活动奖励。通过邮箱、手机号注册的用户将自动下发奖励',
  'task.detail.email.rule5':
    'Thời gian tặng quà sẽ được ghi có kịp thời. Mã phiếu giảm giá được gửi qua email hoặc tin nhắn SMS và bạn chỉ có thể tham gia thông qua URL ngắn trong tin nhắn', //加速时长实时到账。优惠券码通过邮件、短信方式发送，仅可通过信息内的短网址参与',
  'task.detail.email.btn': 'Liên kết và nhận', //绑定并领取',
  'task.detail.email.received': 'Đã nhận', //已领取',
  'task.detail.email.finished': 'Hoạt động đã kết thúc', //活动已结束',
  'task.detail.email.bind': 'Kết nối ngay', //立即绑定',
  'task.detail.email.bound': 'Nhận ngay', //立即领取',
  'task.detail.email.success': 'Nhận thành công', //成功领取',
  'task.detail.email.failed': 'Nhận thất bại', //领取失败',
  'task.detail.email.verify.pleaseEnterCorrectEmail':
    'Xin nhập địa chỉ email chính xác', //'请输入正确的邮箱地址',
  'task.detail.email.verify.pleaseEnterEmail': 'Vui lòng nhập địa chỉ email', //'请输入邮箱地址',
  'task.detail.email.verify.pleaseEnterCorrectPhone':
    'Vui lòng nhập số điện thoại chính xác', //'请输入正确的手机号码',
  'task.detail.email.verify.pleaseEnterPhone': 'Hãy nhập số điện thoại của bạn', //'请输入手机号码',
  'task.detail.email.verify.pleaseEnterCorrectVerifyCode':
    'Vui lòng nhập mã xác minh chính xác', //'请输入正确的验证码',
  'task.detail.email.verify.pleaseEnterVerifyCode':
    'Vui lòng nhập mã chứng nhận', //'请输入验证码',
  'task.detail.email.verify.pleaseEnterDigitPsw':
    'Vui lòng nhập mật khẩu 6-16 ký tự bao gồm chữ số và chữ cái', //'请输入6-16位数字字母组合密码',
  'task.detail.share.title': 'Chia sẻ để nhận thưởng', //分享有奖',
  'task.detail.share.min5m': 'Chọn hình ảnh nhỏ hơn 5M', //请选择小于5M大小的图片',
  'task.detail.share.noPass': 'Kiểm toán thất bại, Gửi lại', //审核不通过，重新提交',
  'task.detail.share.submit': 'Trình xét duyệt', //提交审核',
  'task.detail.share.underReview': 'Đang xét duyệt', //审核中',
  'task.detail.share.pass': 'Duyệt hạch đã đi qua, đã cấp phần thưởng', //审核通过，已发放奖励',
  'task.detail.share.save': 'Lưu ảnh', //保存图片',
  'task.detail.share.cancel': 'Hủy bỏ', //取消',
  'task.detail.share.noUpload': 'Vui lòng tải lên chia sẻ ảnh chụp màn hình', //请先上传分享截图！',
  'task.detail.share.saveSuccess': 'Hình ảnh đã lưu vào Album', //图片已保存至相册',
  'task.detail.share.saveFail': 'Lưu ảnh không thành công!', //保存图片失败！',
  'task.detail.share.successTip':
    'Đã trình duyệt hạch, vui lòng kiên nhẫn chờ đợi', //已提交审核，请耐心等待',
  'task.detail.share.version':
    'Phiên bản này quá thấp, vui lòng hoàn thành liên kết tại trang "Tôi- Tài khoản của tôi"', //当前版本较低，请前往“我的-我的账号”页完成绑定',
  'task.detail.share.message': 'Vui lòng tải lên ảnh', //请上传图片!',
};

// 邀请裂变第4期
const pointEventV4 = {
  'V4.welfare.center.title': '福利中心',
  'V4.welfare.center.more': '更多福利',
  'V4.welfare.foreignStudent.title': '留学生专属福利',
  'V4.welfare.foreignRule.title': '规则说明',
  'V4.welfare.redeem.norecord.goForIt': 'Chưa có hồ sơ nào cả, hãy đi và nhận quyền lợi~',

  'V4.welfare.record.title': '福利记录',
  'V4.welfare.miniGame.title': '小游戏任务',
};

const cancel = {
  'cancel.title.reminder': 'Nhắc nhở hủy', //', //'注销提醒',
  'cancel.title.auth': 'Xác minh danh tính', //', //'身份验证',
  // 'cancel.reminder.artical': `
  //  <div  style="color: #90A1DC; ">
  //       注销是不可逆操作，请认真阅读以下重要提醒：
  //     </div>
  //     <p>
  //       1、注销前请确认已解约所有支付平台的连续包月服务， 可查看对应<a href="{url}">解约教程</a>完成解约。为了保障您的权益，未解约无法完成注销；
  //     </p>
  //     <p>
  //       2、帐号申请注销到注销成功有15天的等待期，在等待期内可随时终止注销。等待期结束后帐号将完成注销；
  //     </p>
  //     <p>
  //       3、帐号一旦被注销，所有帐号数据将清空。被注销帐号无法登录、无法找回。其所有权益，包含但不限于剩余会员天数、金币余额等，也将清空无法找回；
  //     </p>
  //     <p>
  //       4、注销后，该帐号关联的手机号、邮箱地址及第三方登陆信息将释放，登陆信息在释放后可以用于其他帐号注册或绑定；
  //     </p>
  //      <p>
  //       5、已注销账号重新注册时，无法获得该账号之前已享受过的新用户福利，包括但不限于新用户超级VIP时长赠送，邀请活动金币奖励等；
  //     </p>
  // `,
  'cancel.reminder.artical': `
   <div  style="color: #90A1DC; ">
        Việc hủy bỏ không thể đảo ngược, hãy đọc kỹ những lời khuyên quan trọng sau đây: 
      </div>
      <p>
        1、Hãy xác nhận đã hủy tất cả các dịch vụ thanh toán liên tục hằng tháng của nền tảng thanh toán trước khi hủy bỏ, xem các <a href="{url}">hướng dẫn hủy bỏ</a> tương ứng để hoàn thành quá trình hủy bỏ. Để đảm bảo quyền lợi của quý khách, không thể hủy bỏ trước khi giải ước; 
      </p>
      <p>
        2、quá trình yêu cầu hủy bỏ tài khoản đến khi tài khoản được hủy thành công sẽ có 15 ngày chờ đợi, trong thời gian chờ đợi này có thể chấm dứt hủy bỏ bất cứ lúc nào. Sau khi hết thời gian chờ đợi tài khoản sẽ được hủy bỏ hoàn thành; 
      </p>
      <p>
        3、Nếu tài khoản đã bị hủy bỏ, tất cả dữ liệu của tài khoản sẽ bị xóa. Tài khoản bị hủy bỏ sẽ không thể đăng nhập, không thể khôi phục. Tất cả các quyền lợi, bao gồm nhưng không giới hạn số ngày thành viên còn lại, số dư xu vàng, v.v., đều sẽ bị xóa và không thể khôi phục nữa; 
      </p>
      <p>
        4、Sau khi hủy bỏ, số điện thoại, địa chỉ email và thông tin đăng nhập bên thứ ba liên kết với tài khoản này sẽ được giải phóng và có thể dùng để đăng ký hoặc liên kết với tài khoản khác; 
      </p>
       <p>
        5、Khi đăng ký lại tài khoản đã hủy sẽ không thể nhận được phúc lợi người dùng mới đã từng được hưởng, bao gồm nhưng không giới hạn tặng thời gian siêu VIP cho người dùng mới, phần thưởng xu vàng hoạt động mời...
      </p>
  `,
  'cancel.reminder.agree': 'Đã đọc và đồng ý: QuickFox ', //'已阅读并同意：QuickFox ',
  'cancel.reminder.agreement': 'Thỏa thuận hủy tài khoản', //'帐号注销协议',
  'cancel.reminder.next': 'Bước tiếp theo', //'下一步',
  'cancel.reminder.read': 'Hãy đọc kỹ nhắc nhở trên', //'请认真阅读上述提醒',
  'cancel.reminder.cancelSign':
    'Hiện tại còn chưa hủy bỏ dịch vụ gia hạn hằng tháng liên tục {signTypeText}, vui lòng xác nhận hoàn thành việc hủy bỏ trước để thực hiện thao tác hủy bỏ', //当前尚未解约{signTypeText}连续包月服务，请确认完成解约后再进行注销操作',
  'cancel.auth.input':
    'Vui lòng nhập mật khẩu tài khoản này để hoàn tất xác thực', //请输入当前账号密码完成验证',
  'cancel.auth.confirm': 'Xác nhận hủy', //确认注销
  'cancel.auth.tips':
    'Sau khi gửi yêu cầu hủy bỏ, bạn sẽ có 15 ngày chờ đợi, trong thời gian chờ đợi có thể đăng nhập lại và chấm dứt hủy bỏ', //提交注销后，您将有15天注销等待期，等待期内可随时重新登录以终止注销。',
  'cancel.auth.pwd': 'Vui lòng nhập mật khẩu', //请输入密码
  'cancel.auth.relogin.third':
    'Vui lòng đăng nhập vào bất cứ tài khoản bên thứ ba nào để hoàn tất xác thực', //请重新登录任一第三方账号以完成验证',
  'cancel.auth.relogin': 'Đăng nhập lại', //重新登录
  'cancel.auth.please': 'Vui lòng', //请',
  'cancel.auth.finish': 'Đã hoàn thành xác nhận', //已完成验证
  'cancel.auth.unDetected':
    'Chưa phát hiện tài khoản bên thứ ba, vui lòng liên lạc với DVKH để được hỗ trợ', //未检测到第三方账号，请尝试联系客服处理',
  'cancel.auth.inconsistent':
    'Tài khoản bên thứ ba được đăng nhập lần này không nhất trí với tài khoản bên thứ ba đã liên kết, vui lòng thử lại', //本次登录第三方帐号与当前绑定第三方账号不一致，请重试',
  'cancel.auth.failed': 'Xác minh tài khoản bên thứ ba không thành công', //第三方账号校验失败
  'cancel.auth.failed.accident': 'Xác minh không thành công', //'验证失败',
  'cancel.wx.pay': 'Tự động gia hạn', //管理自动续费',
  'cancel.tips.userInfoFailed':
    'Chưa nhận được thông tin người dùng, vui lòng thử lại sau', //'未获取到用户信息，请稍后重试',
  'cancel.tips.unexpectedError': 'Có lỗi bất ngờ', //'发生意外的错误',
  'cancel.tips.failToCheckSignStatus':
    'Tìm kiếm trạng thái ký kết không thành công', //查询签约状态失败
};
const memberi = {
  'memberi.title': 'Mã đổi thưởng', //兑换QuickFox超级VIP',
  'memberi.valicode': 'Vui lòng nhập mã ưu đãi', //请输入兑换码',
  'memberi.loginTip': 'Đăng nhập trước', //登录后才可以兑换哦',
  'memberi.loginBtn': 'Đăng nhập/đăng ký', //登录/注册',
  'memberi.placeholder': 'Nhập mã đổi quà,trường hợp nhạy cảm', //请输入兑换码,注意区分大小写',
  'memberi.getcode': 'Chuyển đổi ngay', //立即兑换',
  'memberi.descTitle': 'Quy tắc chuyển đổi', //兑换规则',
  'memberi.desc1':
    'Mã đổi thưởng là sản phẩm phúc lợi độc quyền của QuickFox. Vui lòng không bán mã đổi thưởng hoặc tham gia vào bất kỳ hoạt động thương mại nào khác. Nếu vi phạm, QuickFox có quyền xóa thời gian thành viên', //兑换码可通过参与QuickFox或合作商家相关活动获得',
  'memberi.desc2':
    'Sau khi đổi thưởng thành công, bạn có thể nhận được thời gian VIP tương ứng', //兑换成功即可获得对应的超级VIP时长',
  'memberi.desc3':
    'Người dùng đã có thời gian thành viên VIP và người dùng vẫn còn trong trạng thái hợp đồng sẽ được gia hạn thời gian VIP sau khi đổi thưởng thành công', //已拥有超级VIP会员时长的用户，及仍在签约状态中的用户，将在兑换成功后顺延超级VIP时长',
  'memberi.desc4':
    'QuickFox có quyền diễn giải các quy tắc này trong phạm vi luật pháp. Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với dịch vụ khách hàng trực tuyến', //QuickFox在法律规定的范围内享有本规则的解释权。如有疑问，请咨询联系在线客服',
  'memberi.success': 'Đổi thành công', //兑换成功',
  'memberi.fail': 'Đổi thưởng không thành công', //兑换失败,
  'memberi.success1': 'Đã nhận được', //已获得',
  'memberi.ok': 'Vâng', //好的',
  'memberi.blacklistTips':
    'Tài khoản của bạn đang gặp rủi ro và không thể đổi quà', // '当前帐号存在风险，无法使用兑换码',
};

const customizedMembership = {
  'customizedMembership.viptimer': 'Thời gian còn lại', //时长剩余',
  'customizedMembership.concat': 'Liên hệ ngay với DVKH để', //立即咨询客服，获得独享定制线路',
  'customizedMembership.desc1': 'Đảm bảo băng thông rộng riêng biệt', //独享带宽保障',
  'customizedMembership.desc2': 'Phát video 4K nhanh chóng', //4K影音秒开',
  'customizedMembership.desc3': 'Live stream mượt mà', //流畅开播',
  'customizedMembership.desc4':
    'Đường truyền chính và dự bị để đảm bảo ổn định gấp đôi', //主备双线双倍稳定',
  'customizedMembership.desc5': 'Ba thiết bị kết nối cùng lúc', //三设备同时在线',
  'customizedMembership.desc6': 'Hỗ trợ tất cả các nền tảng khách hàng', //全客户端支持',
  'customizedMembership.btn': 'Tư vấn để gia hạn', //咨询续费',
  'customizedMembership.btn1': 'Tư vấn để mua', //咨询购买',
  'customizedMembership.title': 'Dòng tùy chỉnh',
};

const sportsQuiz = {
  'sportsQuiz.title': 'Chi tiết thi đấu', //赛事详情',
  'sportsQuiz.shareAndGetCoins': 'Chia sẻ nhanh để kiếm đồng xu vàng', //快速分享赚金币',
  'sportsQuiz.how2play': 'Giới thiệu phương thức chơi', //玩法介绍',
  'sportsQuiz.rule': 'Luật chơi hoạt động World cup', //玩转世界杯活动规则',
  'sportsQuiz.guessed': 'Bạn đã dự đoán', //您已竞猜',
  'sportsQuiz.advance': 'Nâng cấp', //晋级',
  'sportsQuiz.win': 'Tháng', //获胜',
  'sportsQuiz.draw': 'Hòa', //平局',
  'sportsQuiz.guessWithCoins': 'Dự đoán với {coins} đồng xu', //使用{coins}金币竞猜',
  'sportsQuiz.ifGuessRight': 'Dự đoán đúng sẽ nhận được', //竞猜正确即可获得',
  'sportsQuiz.includes':
    'Kết quả trận đấu Bao gồm hiệp phụ và loạt sút luân lưu', //比赛结果包含加时赛，点球大战',
  'sportsQuiz.leftTime': 'Thời gian dự đoán còn lại', //剩余竞猜时间',
  'sportsQuiz.leftQuota': 'Quyền tham gia còn lại', //剩余参与名额',
  'sportsQuiz.end': 'Cuộc dự đoán trận đấu này đã kết thúc', //本场赛事竞猜已结束',
  'sportsQuiz.others': 'Vui lòng theo dõi các trận đấu khác~', //请关注其他场次比赛~',
  'sportsQuiz.getFromNotice': ' (Nhận qua Trung tâm tin nhắn) ', //（通过消息中心领取）',
  'sportsQuiz.youHaveGuessed': 'Bạn đã tham gia dự đoán', //您已参与竞猜',
  'sportsQuiz.ifThen': 'Nếu {predict}{result}tháng thì bạn sẽ lấy được', //若 {predict}{result}您可获得 ',
  'sportsQuiz.guessedRight': 'Chúc mừng bạn dự đoán đúng, của bạn', //恭喜竞猜正确，您的 ',
  'sportsQuiz.goGet': 'đã được cấp, mao lên nhận tại Trung tâm tin nhắn~', // 已发放，快去消息中心领取吧～',
  'sportsQuiz.yourGuess': 'Dự đoán của bạn là: ', //您的竞猜为：',
  'sportsQuiz.guessedWrong': 'Dự đoán sai, hãy cố gắng trong lần sau nhé', //竞猜错误，下次继续加油哦~',
  'sportsQuiz.goGetCoins': 'Đi kiếm đồng xu vàng', //去赚金币',
  'sportsQuiz.platforms':
    'Bạn có thể xem trận đấu tại các nền tảng phát trực tiếp sau', //您可以前往以下直播平台观战',
  'sportsQuiz.useQF':
    'Sử dụng chế độ Quickfox video để mở khóa quyền hạn nhanh chóng', //使用Quickfox影音模式，一键解锁版权限制',
  'sportsQuiz.cctv': 'CCTV', //央视影音',
  'sportsQuiz.yangshipin': 'CCTV video', //央视频',
  'sportsQuiz.migu': 'Migu video', //咪咕视频',
  'sportsQuiz.tiktok': 'Tiktok', //抖音',
};

const device = {
  'device.title': 'Thiết bị đã đăng nhập', //已登录设备',
  'device.dialog.title': 'Đăng xuất', //退出登录',
  'device.dialog.centent':
    'Thiết bị này muốn đăng nhập lại cần phải nhập mật khẩu tài khoản xác minh sau khi thoát, bạn có chắc muốn thoát không?', //退出登录后该设备再次登录需要验证账号密码，您确定要退出吗',
  'device.dialog.ok': 'Xác định', //确定',
  'device.dialog.cancel': 'Hủy bỏ', //取消',
  'applyRecord.title': 'Lịch sử đăng ký', //申请记录',
  'device.thisDevice': 'Máy này', //本机
  'device.exit': 'Thoát', //退出
  'device.offline': 'Ngoại tuyến', //下线
  'device.exitSuccess': 'Thoát thành công', //'退出成功',
  'applyRecord.exitFailed': 'Thể đăng ký', //已拒绝
  'applyRecord.using': 'Đang sử dụng', // 已上架
  'applyRecord.waiting': 'Đang xuất', //待处理
};

// 绑定邮箱活动
const bindEmail = {
  'bindEmail.title': 'Liên kết email',
  'bindEmail.success.tip': 'Liên kết email số điện thoại thành công!',
  'bindEmail.areaCode': 'Số vùng',
  'bindEmail.phoneEmpty': 'điện thoại',
  'bindEmail.emailEmpty': 'email',
  'bindEmail.switchTo': 'Chuyển sang',
  'bindEmail.getCode': 'Nhận Mã chứng nhận',
  'bindEmail.findCountry': 'Vui lòng nhập Mã quốc gia hoặc số vùng để tìm',
  'bindEmail.emailVerify': 'Xin nhập địa chỉ email chính xác',
  'bindEmail.phoneVerify': 'Vui lòng nhập số điện thoại chính xác',
  'bindEmail.codeVerify': 'Vui lòng nhập mã xác minh chính xác',
  'bindEmail.pwsVerify':
    'Vui lòng nhập mật khẩu ký tự bao gồm chữ số và chữ cái',
  'bindEmail.pwsEmpty': 'Vui lòng nhập mật khẩu',
  'bindEmail.codeEmpty': 'Vui lòng nhập mã chứng nhận',
  'bindEmail.phone': 'điện thoại',
  'bindEmail.email': 'email',
};

// 任务补充
const taskSupplement = {
  'taskSupplement.redemptionDetails': 'Trang chi tiết chuyển đổi',
  'taskSupplement.missionRules': 'quy tắc',
  'taskSupplement.shareScreenshot': 'Chọn chia sẻ ảnh chụp màn hình',
  'taskSupplement.supportChannel':
    'Ảnh chụp màn hình chia sẻ hiện đang hỗ trợ các nền tảng như Xiaohongshu Wechat Weibo và Tiktok...',
  'taskSupplement.advisoryService':
    'Vui lòng tuân thủ các quy tắc nhiệm vụ, nếu có thắc mắc gì hãy tư vấn dịch vụ khách hàng',
  'taskSupplement.auditPrompt1':
    'Vui lòng giữ nội dung được chia sẻ hiển thị trong thời gian phê duyệt',
  'taskSupplement.auditPrompt2':
    'Sau khi phê duyệt sẽ tự động phát hành phần thưởng đồng xu vàng vàng, hãy chờ đợi nhé',
  'taskSupplement.sharePoster': 'Chia sẻ áp phích ',
  'taskSupplement.saveImg': 'Giữ ảnh để lưu',
  'taskSupplement.rulesDesc4':
    'Từ bây giờ, tất cả người dùng đã đăng ký có thể chia sẻ ảnh chụp màn hình với áp phích chia sẻ Q trên các nền tảng xã hội (bao gồm Xiaohongshu, Wechat, Weibo, Douyin, Facebook, Twitter, Instagram, Tiktok, v.v.) trên trang nhiệm vụ và sẽ nhận được phần thưởng 100 đồng xu vàng vàng sau khi phê duyệt',
  'taskSupplement.rulesDesc5':
    'Mỗi người dùng có thể hoàn thành một nhiệm vụ và trong quá trình phê duyệt nhiệm vụ, hãy giữ nội dung được chia sẻ hiển thị trên nền tảng xã hội, nếu không có thể dẫn đến phê duyệt không được',
  'taskSupplement.rulesDesc6':
    'Nền tảng sẽ hoàn thành phê duyệt trong 2 ngày làm việc, hãy chờ đợi',
  'taskSupplement.rulesDesc7':
    'Trong quá trình tham gia hoạt động tiếp thị, nếu người dùng sử dụng quyền lợi ưu đãi để kiếm lợi tức hoặc lợi nhuận bất hợp pháp, chuyển nhượng quyền lợi ưu đãi theo bất kỳ hình thức nào, sử dụng không đúng mục đích, hoặc Q có lý do hợp lý nghi ngờ người dùng sử dụng công cụ ưu đãi hoặc quyền lợi ưu đãi không đúng cách, Q sẽ hủy bỏ tư cách đặc quyền của người dùng, và có quyền hủy bỏ giao dịch không hợp lệ, thu hồi quyền lợi ưu đãi (bao gồm cả đã sử dụng và chưa sử dụng)',
  'taskSupplement.rulesDesc8':
    'Nếu có bất kỳ thông tin và trợ giúp nào về hoạt động này, vui lòng tư vấn dịch vụ khách hàng trực tuyến Q, hoạt động này không liên quan đến Công ty Apple (Apple Inc.);',
  'taskSupplement.rulesDesc9':
    'Trong phạm vi pháp lý, Q bảo lưu quyền giải thích cuối cùng cho hoạt động này',
  'taskSupplement.xiecheng': 'Trang đích Ctrip',
};

// 微信续费补充
const wxPayManage = {
  'wxPayManage.activatedBusiness': 'Dịch vụ đã mở',
  'wxPayManage.weChatPay': 'Thanh toán bằng Wechat',
  'wxPayManage.continuousMonthlyMembership': 'Thành viên gói tháng liên tục',
  'wxPayManage.nextRenewalTime': 'Thời gian tự động trừ tiền lần sau',
  'wxPayManage.stopService': 'Tắt dịch vụ',
  'wxPayManage.successfullyUnsubscribe':
    'Hủy gia hạn Gói tháng liên tục  Wechat thành công',
};

// 支付宝实名认证
const zfbManage = {
  'zfbManage.cancelledVerification': 'Ngườ dùng hủy bỏ xác minh',
  'zfbManage.enterNumber': 'Vui lòng nhập số CMND 18 chữ số chính xác',
  'zfbManage.enterX':
    'Nếu số cuối của chứng minh nhân dân có chứa chữ cái Vui lòng nhập',
  'zfbManage.enterID': 'Vui lòng nhập số CMND được đăng ký',
  'zfbManage.enterName': 'Vui lòng nhập họ tên thật đăng ký bằng CMND',
  'zfbManage.verificationFailed': 'Xác minh thông tin CMND không thành công',
  'zfbManage.getCode': 'Vui lòng quay lại máy khách để lấy lại mã QR',
  'zfbManage.realNameverify': '身份校验',
  'zfbManage.successed': '恭喜您完成身份校验',
  'zfbManage.complete': 'Hoàn thành',
  'zfbManage.callChatTextPrefix': '非中华人民共和国居民身份用户，可',
  'zfbManage.callChatText': '联系客服',
  'zfbManage.callChatTextSuffix': '完成校验',
  'zfbManage.authentication': '身份校验使用条款',
  'zfbManage.realNameVerifyDesc1':
    '为保障您的账号安全，请完成身份校验。',
  'zfbManage.realNameVerifyDesc2':
    '当前身份校验服务由“支付宝”提供，请您提前安装并认证相关应用。',
  'zfbManage.realNameVerifyDesc3':
    '您提交的身份信息仅用作身份校验使用，QuickFox承诺不将其应用于任何其他场景，更不会透露给其他第三方。',
  'zfbManage.qRealNameverify': 'QuickFox身份校验',
  'zfbManage.enterRealName': 'Vui lòng nhập họ tên thật của CMND',
  'zfbManage.realName': 'Họ tên thật',
  'zfbManage.enterRealID': 'Vui lòng nhập số CMND với 18 ký tự',
  'zfbManage.IDCode': 'Số CMND',
  'zfbManage.now': '立即校验',
  'zfbManage.go': '前往认证中',
  'zfbManage.upgrade':
    'Máy khách hiện không hỗ trợ giao diện này, hãy nâng cấp',
  'zfbManage.failed': 'Không tải được',
  'zfbManage.sendData': 'Dữ liệu gửi giải mã',
};

const blacklistBlocking = {
  'blacklist.youRisk': 'Tài khoản của bạn đang gặp rủi ro', //'您的帐号存在风险',
  'blacklist.cannot': 'không thể tham gia các nhiệm vụ phúc lợi', //'暂无法参与福利中心任务',
  'blacklist.sendEmail':
    'Nếu có ý kiến phản đối vui lòng liên hệ địa chỉ email info@quickfox.com.cn để khiếu nại', // '如有异议，请邮件联系info@quickfox.com.cn进行申诉',
  'vip.cantuse': 'Tài khoản phụ doanh nghiệp không thể thực hiện thao tác này'
};

const FraudBlacklistBlocking = {
  'blacklist.policy': 'Vì lý do chính sách',
  'blacklist.policyDetail': 'dịch vụ không còn khả dụng ở quốc gia hoặc khu vực của bạn',
  'blacklist.thanks': 'Cảm ơn sự thông cảm và hỗ trợ của bạn!',
};

//
const vi_VN = {
  ...about,
  ...contact,
  ...help,
  ...helpDetail,
  ...http,
  ...memberServiceAgreement,
  ...monthlyVipAgreement,
  ...notice,
  ...tsupport,
  ...Welfare,
  ...PageTitle,
  ...Common,
  ...Tips,
  ...ActivationRecord,
  ...TimeType,
  ...tutorial,
  ...pointEventEnd,
  ...taskCenterV3,
  ...pointEventV4,
  ...cancel,
  ...memberi,
  ...sportsQuiz,
  ...device,
  ...customizedMembership,
  ...bindEmail,
  ...taskSupplement,
  ...wxPayManage,
  ...zfbManage,
  ...blacklistBlocking,
  ...FraudBlacklistBlocking,
};

export default vi_VN;
